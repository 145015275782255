import React from "react";
import {
  BooleanInput,
  required,
  SimpleForm,
  TextInput,
  DateTimeInput,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";

const isRequired = required();

export default function Form({ isNew, ...props }: { isNew?: boolean }) {
  return (
    <SimpleForm {...props}>
      <TextInput disabled source="id" />
      <ReferenceInput
        label="Organisation"
        source="organisation"
        reference="organisations"
        filterToQuery={(q) => q && { name: { ilike: q } }}
        validate={isRequired}
      >
        <AutocompleteInput
          shouldRenderSuggestions={(val: string) => {
            return val.trim().length > 2;
          }}
          optionText="name.en"
          optionValue="id"
          autocomplete="off"
          disabled={isNew ? undefined : true}
        />
      </ReferenceInput>
      <TextInput disabled source="key" />
      <BooleanInput source="active" />
      <DateTimeInput disabled source="createdAt" />
      <DateTimeInput disabled source="updatedAt" />
      <DateTimeInput disabled source="lastUsage" />
    </SimpleForm>
  );
}
