import { REL_TYPE_TO_ONE } from "./constants";

const organisation = {
  attributes: {
    id: { editable: false },
    name: { editable: true },
    address: { editable: true },
    description: { editable: true },
    slug: { editable: true },
    website: { editable: true },
    stripeUserId: { editable: false },
  },
  relationships: {
    plan: {
      type: "plans",
      editable: true,
      relation: REL_TYPE_TO_ONE,
    },
  },
};
export default organisation;
