// This file has been copied and slightly adjusted from
// https://github.com/josdejong/jsoneditor/blob/abbf866e0819bc54bab351879d5723698974b4a7/examples/react_advanced_demo/src/JSONEditorReact.js
import React, { Component } from "react";

import JSONEditor from "jsoneditor";
import "jsoneditor/dist/jsoneditor.css";

export default class JSONEditorReact extends Component {
  componentDidMount() {
    // copy all properties into options for the editor
    // (except the properties for the JSONEditorReact component itself)
    const options = Object.assign({}, this.props);
    delete options.value;

    this.jsoneditor = new JSONEditor(this.container, {
      ...options,
      onChange: this.onChange,
    });

    if ("value" in this.props) {
      this.jsoneditor.set(this.props.value);
    }
  }

  onChange = () => {
    if (this.props.mode === "tree") {
      return this.props.onChange(this.jsoneditor.get());
    }

    try {
      const value = JSON.parse(this.jsoneditor.getText());
      return this.props.onChange(value);
    } catch (e) {
      this.props.onJsonError(e);
    }
  };

  componentDidUpdate() {
    if ("mode" in this.props) {
      this.jsoneditor.setMode(this.props.mode);
    }
  }

  componentWillUnmount() {
    if (this.jsoneditor) {
      this.jsoneditor.destroy();
    }
  }

  render() {
    return (
      <div
        style={{ width: "100%", height: `${this.props.height}px` }}
        ref={(elem) => (this.container = elem)}
      />
    );
  }
}
