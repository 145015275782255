import { stringify } from "qs";
import merge from "deepmerge";
import axios from "axios";
import camelCase from "lodash/camelCase";

import deserialize from "./deserialize";
import serialize from "./serialize";
import defaultSettings from "./default-settings";
import init from "./initializer";
import * as schemas from "../../schemas";

// Set HTTP interceptors.
init();

type GetListParams = {
  pagination: {
    page: number;
    perPage: number;
  };
  sort: {
    order: string;
    field: string;
  };
  filter: object;
};

type GetOneParams = {
  id: string;
};

type CreateParams = {
  id: string;
  data: object;
};

type UpdateParams = {
  id: string;
  data: object;
};

type DeleteParams = {
  id: string;
  query: { [k: string]: string };
};

type GetManyParams = {
  ids: string[];
};

type GetManyReferenceParams = {
  id: string;
  pagination: {
    page: number;
    perPage: number;
  };
  sort: {
    order: string;
    field: string;
  };
  filter: object;
  target: string;
};

export default function dataProvider(apiUrl: string) {
  const settings = merge(defaultSettings, {});

  const options = { headers: settings.headers, withCredentials: true };
  return {
    async getList(resource: string, params: GetListParams) {
      const { page, perPage } = params.pagination;
      const { sort } = params;
      // JSON:API defines DESC sorts with a "-" prefix.
      // reference: https://jsonapi.org/format/#fetching-sorting
      const sortFormatted =
        sort && `${sort.order === "DESC" ? "-" : ""}${sort.field}`;
      const query = {
        "page[number]": page,
        "page[size]": perPage,
        filter: params.filter,
        sort: sortFormatted,
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      const response = await axios({ url, ...options });
      return {
        data: deserialize(response.data.data),
        total: settings.getTotal(response.data.meta),
      };
    },
    async getOne(resource: string, params: GetOneParams) {
      const url = `${apiUrl}/${resource}/${params.id}`;
      const response = await axios({ url, ...options });
      return { data: deserialize(response.data.data) };
    },
    async getMany(resource: string, params: GetManyParams) {
      const query = { filter: JSON.stringify({ id: { in: params.ids } }) };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      const response = await axios({ url, ...options });
      return {
        data: deserialize(response.data.data),
        total: settings.getTotal(response.data.meta),
      };
    },
    async getManyReference(resource: string, params: GetManyReferenceParams) {
      const { sort, pagination, target, id, filter } = params;
      const { page, perPage } = pagination;
      // JSON:API defines DESC sorts with a "-" prefix.
      // reference: https://jsonapi.org/format/#fetching-sorting
      const sortFormatted =
        sort && `${sort.order === "DESC" ? "-" : ""}${sort.field}`;
      const query = {
        "page[number]": page,
        "page[size]": perPage,
        filter: { ...filter, [target]: { eq: id } },
        sort: sortFormatted,
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      const response = await axios({ url, ...options });
      return {
        data: deserialize(response.data.data),
        total: settings.getTotal(response.data.meta),
      };
    },
    async create(resource: string, params: CreateParams) {
      const url = `${apiUrl}/${resource}`;
      const data = serialize(
        // @ts-ignore
        schemas[camelCase(resource)],
        resource,
        params.data,
        params.id,
        "create"
      );
      const response = await axios({
        url,
        ...options,
        method: "POST",
        data: JSON.stringify(data),
      });
      return { data: deserialize(response.data.data) };
    },
    async update(resource: string, params: UpdateParams) {
      const url = `${apiUrl}/${resource}/${params.id}`;

      const data = serialize(
        // @ts-ignore
        schemas[camelCase(resource)],
        resource,
        params.data,
        params.id,
        "update"
      );
      const response = await axios({
        url,
        ...options,
        method: "PATCH",
        data: JSON.stringify(data),
      });
      return { data: deserialize(response.data.data) };
    },
    async delete(resource: string, params: DeleteParams) {
      const url = `${apiUrl}/${resource}/${params.id}${stringify(params.query, {
        addQueryPrefix: true,
      })}`;
      await axios({ url, ...options, method: "DELETE" });
      return { data: { id: params.id } };
    },
    updateMany() {},
    deleteMany() {},
  };
}
