import React from "react";
import { Create } from "react-admin";
import Form from "./Form";

const redirect = (basePath, id, data) => `/calls/${data.call}/show`;
export default function CreateForm(props) {
  return (
    <Create {...props}>
      <Form redirect={redirect} />
    </Create>
  );
}
