import React from "react";
import {
  AutocompleteInput,
  CloneButton,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  TextField,
  SelectInput,
} from "react-admin";

const Filters = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Call"
      source="call.eq"
      reference="calls"
      filterToQuery={(q) => q && { title: { ilike: q } }}
      shouldRenderSuggestions={(val) => {
        return val.trim().length > 2;
      }}
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput optionText="title.en" optionValue="id" />
    </ReferenceInput>
    <SelectInput
      label="Type"
      source="type.eq"
      choices={[
        { id: "fixed", name: "Fixed" },
        { id: "percentage", name: "Percentage" },
      ]}
      alwaysOn
    />
  </Filter>
);

export default function ListPage(props) {
  return (
    <List filters={<Filters />} {...props}>
      <Datagrid>
        <ReferenceField label="Call" source="call" reference="calls">
          <TextField source="title.en" />
        </ReferenceField>
        <DateField source="createdAt" />
        <TextField source="code" />
        <TextField source="type" />
        <NumberField source="value" />
        <NumberField source="usageLimit" />
        <DateField source="expiresAt" />
        <EditButton />
        <CloneButton />
      </Datagrid>
    </List>
  );
}
