import React from "react";
import { Datagrid, List, TextField, ReferenceField } from "react-admin";

export default function ListPage(props) {
  return (
    <List {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <ReferenceField
          label="Feature"
          source="feature"
          reference="product-features"
        >
          <TextField source="label" />
        </ReferenceField>
        <ReferenceField
          label="Organisation"
          source="organisation"
          reference="organisations"
        >
          <TextField source="name.en" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
}
