import React from "react";
import { Datagrid, List, TextField, BooleanField } from "react-admin";

export default function ListPage(props) {
  return (
    <List {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <BooleanField source="active" />
      </Datagrid>
    </List>
  );
}
