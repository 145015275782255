import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/PostAdd";
import { Button, Record } from "react-admin";

export default function AddRelatedResourceButton({
  record,
  label,
  reference,
  target,
}: {
  label: string;
  reference: string;
  target: string;
  record?: Record;
}) {
  if (record === undefined) {
    return null;
  }
  return (
    <Button
      component={Link}
      to={{
        pathname: `/${reference}/create`,
        state: {
          record: { [target]: record.id },
        },
      }}
      label={label}
      title={label}
    >
      <AddIcon />
    </Button>
  );
}
