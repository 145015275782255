import Typography from "@material-ui/core/Typography";

function copyOnClickLink(e) {
  e.preventDefault();
  if (navigator.clipboard) {
    navigator.clipboard.writeText(e.target.href);
  }
}

export default function LinkToCopy({ href }) {
  return (
    <Typography variant="body2" color="textSecondary">
      <a href={href} onClick={copyOnClickLink} title={href}>
        click here to copy
      </a>
    </Typography>
  );
}
