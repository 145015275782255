const plan = {
  attributes: {
    id: { editable: false },
    name: { editable: true },
    slug: { editable: true },
    type: { editable: true },
    specialOfferCode: { editable: true },
    referralDiscountCode: { editable: true },
  },
  relationships: {},
};
export default plan;
