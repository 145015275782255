import React from "react";
import { Edit } from "react-admin";
import Form from "./Form";

const Title = ({ record }) => <span>Edit space user</span>;

const redirect = (basePath, id, data) => `/ws-spaces/${data.space}/show`;

export default function EditForn(props) {
  return (
    <Edit title={<Title />} undoable={false} {...props}>
      <Form redirect={redirect} disableSpaceInput disableUserInput />
    </Edit>
  );
}
