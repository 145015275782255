import { REL_TYPE_TO_ONE } from "./constants";

const invitation = {
  attributes: {
    id: { editable: false },
    email: { editable: ["create"] },
    message: { editable: ["create"] },
    scope: { editable: true },
  },
  relationships: {
    call: {
      type: "calls",
      editable: ["create"],
      relation: REL_TYPE_TO_ONE,
    },
    issuer: {
      type: "users",
      editable: ["create"],
      relation: REL_TYPE_TO_ONE,
    },
  },
};
export default invitation;
