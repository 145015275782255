import { ChangeEvent, useState, useCallback } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import pick from "lodash/pick";
import { DateTime } from "luxon";

export type BirthdayValue = {
  name: "dateOfBirth";
  max?: number;
  min?: number;
  deadline?: string;
};

type BirthdayRange = Omit<BirthdayValue, "name">;

function getInitialAgeRangeValue(value?: BirthdayValue | string) {
  if (value === undefined || typeof value === "string") {
    return null;
  }
  return pick(value, ["min", "max", "deadline"]);
}

const isAgeRangeValid = (value: BirthdayRange | null) => {
  if (value === null) return false;
  if (!value.min && !value.max) return false;
  if (!value.deadline) return false;
  return true;
};

export default function BirthdayRequirement({
  label,
  name,
  value,
  onChange,
}: {
  label: string;
  name: BirthdayValue["name"];
  value?: BirthdayValue | string;
  onChange: (name: string, value: boolean | BirthdayValue) => void;
}) {
  const [ageRange, setAgeRange] = useState<BirthdayRange | null>(
    getInitialAgeRangeValue(value)
  );

  const changeRange = useCallback(
    (fieldName: keyof BirthdayRange) =>
      (event: ChangeEvent<HTMLInputElement>) => {
        const newAgeRange = { ...ageRange, [fieldName]: event.target.value };
        setAgeRange(newAgeRange);
        if (value !== undefined) {
          if (!isAgeRangeValid(newAgeRange)) {
            // If there's no valid age range defined, it's just a simple birthday
            // requirement.
            return onChange(name, true);
          }
          return onChange(name, { name, ...newAgeRange });
        }
      },
    [value, onChange, name, ageRange]
  );

  const changeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
      const newValue = isAgeRangeValid(ageRange) ? { name, ...ageRange } : true;
      onChange(name, isChecked ? newValue : false);
    },
    [onChange, name, ageRange]
  );

  return (
    <>
      <FormControlLabel
        htmlFor={name}
        key={name}
        control={
          <Checkbox
            checked={Boolean(value)}
            id={name}
            color="primary"
            onChange={changeHandler}
            value={name}
          />
        }
        label={label}
      />
      {Boolean(value) && (
        <FormControl style={{ marginLeft: 30 }}>
          <TextField
            id="birthday-deadline"
            label="Deadline"
            type="date"
            name="deadline"
            value={
              ageRange?.deadline
                ? new Date(ageRange.deadline).toISOString().split(/T/)[0]
                : undefined
            }
            onChange={changeRange("deadline")}
            InputLabelProps={{
              shrink: true,
            }}
            variant="filled"
          />
          <div style={{ display: "flex", marginTop: 10 }}>
            <TextField
              id="birthday-min"
              label="Min age"
              type="number"
              name="min"
              value={ageRange?.min}
              onChange={changeRange("min")}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={
                ageRange?.min &&
                ageRange?.deadline &&
                `Youngest date of birth: ${DateTime.fromISO(ageRange.deadline)
                  .minus({
                    years: ageRange.min,
                  })
                  .toISODate()}`
              }
              variant="filled"
            />
            <div style={{ width: 10 }} />
            <TextField
              id="birthday-max"
              label="Max age"
              type="number"
              name="max"
              value={ageRange?.max}
              onChange={changeRange("max")}
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              helperText={
                ageRange?.max &&
                ageRange?.deadline &&
                `Oldest date of birth: ${DateTime.fromISO(ageRange.deadline)
                  .minus({
                    years: ageRange.max + 1,
                  })
                  .plus({ days: 1 })
                  .toISODate()}`
              }
            />
          </div>
        </FormControl>
      )}
    </>
  );
}
