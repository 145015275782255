import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Field, FormRenderProps } from "react-final-form";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {
  SelectInput,
  useGetManyReference,
  useGetOne,
  Button,
  useNotify,
  Title,
  TopToolbar,
  ShowButton,
} from "react-admin";
import { Box } from "@material-ui/core";
import { ObjectField } from "../../../types/custom-form-fields";

const itemTypeOptions = [
  { id: "authors", name: "Authors" },
  { id: "projects", name: "Projects" },
  { id: "files", name: "Files" },
];

// Define minimal types for the call and category. The actual types are more complex,
// but we only need a few fields for this action.
type Category = {
  id: string;
  label: {
    en: string;
  };
  customFields: ObjectField | null;
  imageCustomFields: ObjectField | null;
};

type Call = {
  id: string;
  title: { en: string };
  customProfileFields: ObjectField | null;
};

type Log = {
  timestamp: string;
  message: string;
};

const ApplyCustomFieldLabelsAction = () => {
  // Get call id from the URL
  const { id } = useParams<{ id: string }>();
  // Load call
  const { data: callData } = useGetOne("calls", id) as ReturnType<
    typeof useGetOne
  > & {
    data?: Call;
  };
  const notify = useNotify();

  // Load categories of the call
  const { data: categpriesMap } = useGetManyReference(
    "submission-categories",
    "call",
    id,
    { page: 1, perPage: 100 },
    { field: "id", order: "ASC" },
    {},
    "calls"
  );

  // Convert id-indexed map to an array of categories
  const categories = useMemo(
    () => Object.values(categpriesMap || {}) as Array<Category>,
    [categpriesMap]
  );

  // State to store the logs of the action response
  const [logs, setLogs] = useState<Log[]>();

  const onSubmit = async (values: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/calls/${id}/apply-custom-field-values-as-labels`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
          credentials: "include",
        }
      );

      if (response.ok) {
        const body = (await response.json()) as { logs: Log[] };
        setLogs(body.logs);
        notify("Custom field values applied as labels", { type: "success" });
      } else {
        notify(
          `Error: Custom field values could not be applied as labels ${response.status}`,
          {
            type: "warning",
          }
        );
      }
    } catch (error) {
      if (error instanceof Error) {
        notify(`Error: ${error.message}`, { type: "warning" });
      } else {
        notify(`Error: ${error}`, { type: "warning" });
      }
    }
  };

  return (
    <>
      <TopToolbar>
        <ShowButton basePath="/calls" record={callData} label="Show call" />
      </TopToolbar>
      <Card>
        <Title
          title={`${callData?.title.en}: Apply custom field values as labels`}
        />
        <CardContent>
          <Typography
            variant="body2"
            color="textPrimary"
            style={{ marginBottom: "16px" }}
          >
            Apply labels to submissions based on custom field values. Only
            custom fields with a predefined set of values can be translated to
            labels (e.g. dropdowns or checkboxes). Free text fields are not
            supported, since they would result in too many labels. This form
            only offers custom fields that meet this criteria for the selected
            category.
          </Typography>
          <Form
            onSubmit={onSubmit}
            // @ts-expect-error The error is probably coming from the fact that
            // ActionForm requires `categories` and `call` props, and the typing
            // of the `Form` component isn't really aware of the fact that all unkown props
            // of the `Form` component are passed down to the `ActionForm` component.
            component={ActionForm}
            categories={categories}
            call={callData}
          />
          {logs && (
            <>
              <h2>Response</h2>
              <ul>
                {logs?.map((log, index) => (
                  <li key={index}>
                    {log.timestamp} - {log.message}
                  </li>
                ))}
              </ul>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};

/**
 * Search all custom fields of the category that are of type string
 * and have an enum defined OR that are of type array and have a string items type
 * with an enum defined.
 * @param customFields The custom fields definition
 * @returns Array of custom fields that are relevant for the action
 */
function findRelevantCustomFields(customFields: ObjectField | null) {
  if (!customFields) {
    return [];
  }
  return Object.entries(customFields.properties)
    .filter(
      ([, field]) =>
        (field.type === "string" && field.enum) ||
        (field.type === "array" &&
          field.items.type === "string" &&
          field.items.enum)
    )
    .map(([key, field]) => ({
      key,
      title: field.title,
      isArray: field.type === "array",
    }));
}

function ActionForm({
  handleSubmit,
  call,
  categories,
  pristine,
  submitting,
  values,
  form,
}: FormRenderProps & { categories: Array<Category>; call: Call }) {
  const [customFields, setCustomFields] = useState<
    Array<{ key: string; title: string; isArray: boolean }>
  >([]);
  const { change } = form;

  // Set customFields based on the selected category and itemType
  useEffect(() => {
    const category = categories.find((c) => c.id === values.categoryId);
    if (!category) {
      return;
    }
    if (values.itemType === "projects") {
      const fields = findRelevantCustomFields(category.customFields);
      setCustomFields(fields);
    } else if (values.itemType === "files") {
      const fields = findRelevantCustomFields(category.imageCustomFields);
      setCustomFields(fields);
    } else if (values.itemType === "authors") {
      const fields = findRelevantCustomFields(call.customProfileFields);
      setCustomFields(fields);
    }
    change("customFieldName", "");
  }, [values.itemType, values.categoryId, change, categories, call]);

  // Set isArray based on the selected custom field
  useEffect(() => {
    const selectedCustomField = customFields.find(
      (field) => field.key === values.customFieldName
    );
    if (selectedCustomField) {
      // isArray is an invisible field, since we don't need
      // the user to select it. It is automatically determined.
      change("isArray", selectedCustomField.isArray);
    }
  }, [values.customFieldName, customFields, change]);

  const allFieldsDefined =
    values.categoryId && values.itemType && values.customFieldName;

  // Only offer itemTypeOptions if any relevant customFields are defined
  // for the respective itemType. This is to avoid the user selecting an itemType
  // and then not being able to select a custom field.
  const filteredItemTypeOptions = useMemo(() => {
    const category = categories.find((c) => c.id === values.categoryId);
    if (!category) {
      return [];
    }
    return itemTypeOptions.filter((option) => {
      if (option.id === "authors") {
        return findRelevantCustomFields(call.customProfileFields).length > 0;
      } else if (option.id === "projects") {
        return findRelevantCustomFields(category.customFields).length > 0;
      } else if (option.id === "files") {
        return findRelevantCustomFields(category.imageCustomFields).length > 0;
      }
      return false;
    });
  }, [values.categoryId, categories, call]);

  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <Field
          name="categoryId"
          component={SelectInput}
          source="categoryId"
          choices={categories.map((category) => ({
            id: category.id,
            name: category.label.en,
          }))}
          required
          helperText="Select the category to apply the custom field values as labels"
        />
      </Box>
      <Box>
        <Field
          name="itemType"
          component={SelectInput}
          source="itemType"
          disabled={filteredItemTypeOptions.length === 0}
          choices={filteredItemTypeOptions}
          required
          helperText="On which type of item should the labels be applied?"
        />
      </Box>
      <Box>
        <Field
          name="customFieldName"
          component={SelectInput}
          source="customFieldName"
          disabled={customFields.length === 0}
          choices={customFields?.map((field) => ({
            id: field.key,
            name: field.title,
          }))}
          required
          helperText="Select the custom field that should be translated to labels"
        />
      </Box>
      <Button
        type="submit"
        disabled={submitting || pristine || !allFieldsDefined}
        label="Submit"
        size="large"
        variant="contained"
        color="primary"
      />
    </form>
  );
}

export default ApplyCustomFieldLabelsAction;
