import React, { useEffect, useRef } from "react";
import { required, SelectInput, TextInput } from "react-admin";
import { useFormState, useForm } from "react-final-form";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import FileUploader from "../../../components/FileUploader";

const requiredValidator = required("Required");
const acceptVideo = "video/*";
const acceptImage = "image/*";
const bannerSpecs = {
  headline: false,
  button: false,
  mediaType: ["image", "video"],
  files: [
    {
      fileSpecs: "📐 Target: mobile; ratio 1:1; 1200x1200px",
    },
    {
      fileSpecs: "📐 Target: desktop; ratio 4:1; 2400x600px",
    },
  ],
};

const gridCardSpecs = {
  headline: true,
  mediaType: ["image", "video"],
  files: [
    {
      fileSpecs: "📐 Ratio 4:3; 1200x900px",
    },
  ],
};

const loginScreenSpecs = {
  mediaType: ["image", "video"],
  files: [
    {
      fileSpecs: "📐 Ratio 1:1; 1200x1200px",
    },
  ],
};

const emailSpecs = {
  headline: true,
  button: true,
  mediaType: "image",
  files: [
    {
      fileSpecs: "📐 Ratio 4:3; 1200x900px",
    },
  ],
};

const newsletterSpecs = {
  mediaType: "image",
  files: [
    {
      fileSpecs: "📐 Ratio 4:3; 1200x900px",
    },
  ],
};

const dedicatedNewsletterSpecs = {
  mediaType: "image",
  files: null,
};

function getSpecsBySlot(adSlot) {
  switch (adSlot) {
    case "Contest Grid Banner":
    case "Submission Complete Page":
      return bannerSpecs;
    case "Login Screen":
      return loginScreenSpecs;
    case "Submission Complete Email":
      return emailSpecs;
    case "Newsletter":
      return newsletterSpecs;
    case "Dedicated Newsletter":
      return dedicatedNewsletterSpecs;
    case "Contest Grid Card":
    default:
      return gridCardSpecs;
  }
}

function AdContent({ specs }) {
  const {
    values: { content },
  } = useFormState();
  return (
    <div
      style={{ display: "flex", flexDirection: "column", maxWidth: "356px" }}
    >
      <Typography variant="h5">Content</Typography>
      {specs.headline && (
        <TextInput
          source="content.headline"
          label="Headline"
          validate={requiredValidator}
        />
      )}
      <Typography variant="h6">Files</Typography>
      {Array.isArray(specs.mediaType) ? (
        <SelectInput
          label="Type"
          source="content.mediaType"
          defaultValue={
            !Array.isArray(specs.mediaType) ? specs.mediaType : undefined
          }
          disabled={!Array.isArray(specs.mediaType) ? true : undefined}
          choices={[
            { id: "image", name: "image" },
            { id: "video", name: "video" },
          ]}
          validate={requiredValidator}
        />
      ) : (
        <TextInput source="content.mediaType" label="Type" disabled />
      )}
      {specs.files && specs.files.length > 0 && (
        <>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ marginBottom: "10px" }}
          >
            <span role="img" aria-label="warning">
              ⚠️
            </span>{" "}
            Files are not optimzed for web after uploading. Please provide files
            that are ready for the web.
          </Typography>
          <div style={{ display: "flex" }}>
            {specs.files.map((spec, index) => (
              <Paper
                key={`banner-ad-${index}`}
                style={{
                  padding: "20px",
                  marginRight: "20px",
                  minWidth: 300,
                  display: "flex",
                  flexDirection: "column",
                }}
                variant="outlined"
              >
                <Typography variant="body2" style={{ marginBottom: "10px" }}>
                  {spec.fileSpecs}
                </Typography>
                <FileUploader
                  source={`content.files[${index}].src`}
                  label="File"
                  accept={
                    content && content.mediaType === "video"
                      ? acceptVideo
                      : acceptImage
                  }
                />
                <TextInput
                  label="File URL"
                  source={`content.files[${index}].src`}
                  validate={requiredValidator}
                  helperText="You can either upload a file or specify the file-url in this text
              field."
                />
                {content && content.mediaType === "video" && (
                  <>
                    <FileUploader
                      source={`content.files[${index}].poster`}
                      label="Poster"
                      accept={acceptImage}
                    />
                    <TextInput
                      label="Poster File URL"
                      source={`content.files[${index}].poster`}
                      validate={requiredValidator}
                      helperText="You can either upload a file or specify the file-url in this text
              field."
                    />
                  </>
                )}
              </Paper>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default function AdContentInput(props) {
  const {
    values: { adSlot },
  } = useFormState();
  const { change } = useForm();
  const specs = getSpecsBySlot(adSlot);
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current === true) {
      firstRender.current = false;
      return;
    }
    switch (adSlot) {
      case "Contest Grid Banner":
      case "Submission Complete Page":
        change("content", {});
        break;
      case "Login Screen":
        change("content", {});
        break;
      case "Submission Complete Email":
      case "Dedicated Newsletter":
      case "Newsletter":
        change("content", { mediaType: "image" });
        break;
      case "Contest Grid Card":
      default:
        change("content", {});
    }
  }, [adSlot, change]);
  return <>{Boolean(adSlot) && <AdContent specs={specs} />}</>;
}
