import {
  Show,
  TextField,
  ReferenceManyField,
  ReferenceField,
  DateField,
  Datagrid,
  EditButton,
  CloneButton,
  SimpleShowLayout,
  Pagination,
  ShowProps,
  BooleanField,
  Record,
  useQueryWithStore,
  DeleteButton,
  TopToolbar,
  Button,
} from "react-admin";
import { useState } from "react";
import { makeStyles, Box, Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddRelatedResourceButton from "./AddRelatedResourceButton";
import AutoFinishSubmissions from "./actions/AutoFinishSubmissions";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  growColumn: {
    width: "100%",
  },
}));

function Title({ record }: { record?: Record }) {
  return <span>{record ? `${record.title.en}` : ""}</span>;
}

function PreconditionStep({ record }: { record: Record }) {
  const { data, loading } = useQueryWithStore({
    type: "getOne",
    resource: "call-preconditions",
    payload: { id: record.id },
  });
  if (loading) {
    return "Loading...";
  }

  if (!data) {
    return (
      <AddRelatedResourceButton
        label="Add precondition step"
        reference="call-preconditions"
        target="call"
        record={record}
      />
    );
  }

  return (
    <EditButton
      label="Edit preconditions"
      basePath="/call-preconditions"
      record={data}
    />
  );
}

function QuickInfo({ record }: { record: Record }) {
  const { data, loading } = useQueryWithStore({
    type: "getOne",
    resource: "call-infos",
    payload: { id: record.id },
  });
  if (loading) {
    return "Loading...";
  }

  if (!data) {
    return (
      <AddRelatedResourceButton
        label="Add quick info"
        reference="call-infos"
        target="call"
        record={record}
      />
    );
  }

  return (
    <EditButton label="Edit quick info" basePath="/call-infos" record={data} />
  );
}

const Actions = ({ data }: { data?: any }) => {
  const history = useHistory();
  // Menu open/close handlers. These are intricasies of the Material UI Menu component.
  // Clicking the Actions button will register the button as the anchor element, and the
  // menu will be rendered at the position of the anchor element.
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <TopToolbar>
      {data && (
        <>
          <Box mr={2}>
            {/* Actions menu */}
            <Button
              aria-controls="actions-menu"
              aria-haspopup="true"
              onClick={handleMenuOpen}
              startIcon={<MoreVertIcon />}
              label="Actions"
            />
            <Menu
              id="actions-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem
                onClick={() => {
                  history.push(`/calls/${data.id}/apply-custom-field-labels`);
                  handleMenuClose();
                }}
              >
                Apply custom field labels
              </MenuItem>
              <AutoFinishSubmissions resource={data} />
            </Menu>
          </Box>

          <EditButton record={data} />
        </>
      )}
    </TopToolbar>
  );
};

export default function ShowPage({ id, ...props }: ShowProps) {
  const classes = useStyles();

  return (
    <Show title={<Title />} id={id} actions={<Actions />} {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField label="Title" source="title.en" />
        <ReferenceField
          label="Organisation"
          source="organisation"
          reference="organisations"
          link="show"
        >
          <TextField source="name.en" />
        </ReferenceField>
        <TextField source="visibilityStatus" />
        <BooleanField source="useNewSubmissionWizard" />
        <DateField label="Start" source="submissionStartDate" />
        <DateField label="End" source="submissionEndDate" />
        <h2>Categories</h2>
        <AddRelatedResourceButton
          label="Add category"
          reference="submission-categories"
          target="call"
        />
        <ReferenceManyField
          addLabel={false}
          reference="submission-categories"
          target="call"
          pagination={<Pagination />}
        >
          <Datagrid>
            <TextField
              label="Name"
              source="label.en"
              headerClassName={classes.growColumn}
            />
            <EditButton />
            <CloneButton />
          </Datagrid>
        </ReferenceManyField>
        <h2>Preconditions</h2>
        {/* @ts-ignore */}
        <PreconditionStep />
        <h2>Quick Info</h2>
        {/* @ts-ignore */}
        <QuickInfo />
        <h2>Call Pages</h2>
        <AddRelatedResourceButton
          label="Add call page"
          reference="call-pages"
          target="call"
        />
        <ReferenceManyField
          addLabel={false}
          reference="call-pages"
          target="call"
          pagination={<Pagination />}
        >
          <Datagrid>
            <TextField
              label="Language"
              source="language"
              headerClassName={classes.growColumn}
            />
            <EditButton />
            <CloneButton />
          </Datagrid>
        </ReferenceManyField>
        <h2>Team</h2>
        <AddRelatedResourceButton
          label="Add person"
          reference="call-users"
          target="call"
        />
        <ReferenceManyField
          addLabel={false}
          reference="call-users"
          target="call"
          pagination={<Pagination />}
        >
          <Datagrid>
            <ReferenceField label="User" source="user" reference="users">
              <TextField source="email" />
            </ReferenceField>
            <TextField source="scope" headerClassName={classes.growColumn} />
            <BooleanField source="active" />
            <EditButton />
            <DeleteButton redirect={false} />
          </Datagrid>
        </ReferenceManyField>
        <h2>Invitations</h2>
        <AddRelatedResourceButton
          label="Invite person"
          reference="invitations"
          target="call"
        />
        <ReferenceManyField
          addLabel={false}
          reference="invitations"
          target="call"
          pagination={<Pagination />}
        >
          <Datagrid>
            <TextField source="email" />
            <TextField source="scope" />
            <ReferenceField
              label="User"
              source="invitedUser"
              reference="users"
              headerClassName={classes.growColumn}
            >
              <TextField source="publicName" />
            </ReferenceField>
            <BooleanField source="accepted" />
            <EditButton />
            <CloneButton />
            <DeleteButton redirect={false} />
          </Datagrid>
        </ReferenceManyField>
        <h2>Webhooks</h2>
        <AddRelatedResourceButton
          label="Add webhook"
          reference="call-webhooks"
          target="call"
        />
        <ReferenceManyField
          addLabel={false}
          reference="call-webhooks"
          target="call"
          pagination={<Pagination />}
        >
          <Datagrid>
            <TextField source="eventType" />
            <TextField source="url" headerClassName={classes.growColumn} />
            <BooleanField source="active" />
            <EditButton />
            <CloneButton />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
}
