import { ChangeEvent, useCallback } from "react";
import { useInput, Labeled, InputProps } from "react-admin";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import RestrictRequirement, { RestrictValue } from "./RestrictRequirement";
import BirthdayRequirement, { BirthdayValue } from "./BirthdayRequirement";
import countryList from "country-list";
import { profileFields } from "./profileFields";

const countries = countryList
  .getData()
  .map(({ name, code }) => ({ label: name, value: code }));

const genders = [
  { label: "Female", value: "female" },
  { label: "Male", value: "male" },
  { label: "Other", value: "other" },
];

type Value = string | RestrictValue | BirthdayValue;

type ProfileRequirementsProps = InputProps & {};

const choices = profileFields.filter((field) => field.canBeRequired);

const choiceOrder = choices.reduce(
  (map: { [key: string]: number }, { value }, index) => {
    map[value] = index;
    return map;
  },
  {}
);

const simpleChoices = choices.filter((field) => field.isSimpleRequirement);

// Values should always be ordered as defined in the choices list, since
// the requirements array will be iterated in the frontend to display
// profile requirements to the user.
function sortValues(values: Value[]) {
  return values.sort((a, b) => {
    const aIndex = choiceOrder[typeof a === "string" ? a : a.name];
    const bIndex = choiceOrder[typeof b === "string" ? b : b.name];
    return aIndex - bIndex;
  });
}

export default function ProfileRequirements(props: ProfileRequirementsProps) {
  const {
    input: { value, onChange },
  } = useInput(props) as {
    input: { name: string; value: Value[]; onChange: (value: any) => void };
  };

  const checkboxHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
      const newValue = event.target.value;
      if (isChecked) {
        onChange(sortValues([...(value || []), ...[newValue]]));
      } else {
        onChange(value.filter((v: Value) => v !== newValue));
      }
    },
    [onChange, value]
  );

  // Used by complex requirements, that allow additional inputs when selected
  const complexChangeHandler = useCallback(
    (name: string, newValue: Value | boolean) => {
      if (!newValue) {
        return onChange(
          value.filter((v: Value) =>
            typeof v !== "string" ? v.name !== name : v !== name
          )
        );
      }
      onChange(
        sortValues([
          ...value.filter((v: Value) =>
            typeof v !== "string" ? v.name !== name : v !== name
          ),
          newValue === true ? name : newValue,
        ])
      );
    },
    [onChange, value]
  );

  return (
    <Labeled label="Profile Requirements">
      <FormGroup>
        {/* List of simple requirements */}
        {simpleChoices.map((choice) => (
          <FormControlLabel
            htmlFor={choice.value}
            key={choice.value}
            control={
              <Checkbox
                checked={
                  value
                    ? value.find((v: Value) => v === choice.value) !== undefined
                    : false // eslint-disable-line eqeqeq
                }
                id={choice.value}
                color="primary"
                onChange={checkboxHandler}
                value={choice.value}
              />
            }
            label={choice.label}
          />
        ))}
        {/* List of complex requirements */}
        <BirthdayRequirement
          key="dateOfBirth"
          label="Date of birth"
          name="dateOfBirth"
          value={
            value
              ? (value.find(
                  (v: Value) =>
                    (typeof v !== "string" && v.name === "dateOfBirth") ||
                    v === "dateOfBirth"
                ) as BirthdayValue | string | undefined)
              : undefined
          }
          onChange={complexChangeHandler}
        />
        <RestrictRequirement
          key="gender"
          label="Gender"
          name="gender"
          value={
            value
              ? (value.find(
                  (v: Value) =>
                    (typeof v !== "string" && v.name === "gender") ||
                    v === "gender"
                ) as RestrictValue | string | undefined)
              : undefined
          }
          onChange={complexChangeHandler}
          options={genders}
        />
        <RestrictRequirement
          key="countryResidence"
          label="Country residence"
          name="countryResidence"
          value={
            value
              ? (value.find(
                  (v: Value) =>
                    (typeof v !== "string" && v.name === "countryResidence") ||
                    v === "countryResidence"
                ) as RestrictValue | string | undefined)
              : undefined
          }
          onChange={complexChangeHandler}
          options={countries}
        />
        <RestrictRequirement
          key="countryOrigin"
          label="Country origin"
          name="countryOrigin"
          value={
            value
              ? (value.find(
                  (v: Value) =>
                    (typeof v !== "string" && v.name === "countryOrigin") ||
                    v === "countryOrigin"
                ) as RestrictValue | string | undefined)
              : undefined
          }
          onChange={complexChangeHandler}
          options={countries}
        />
      </FormGroup>
    </Labeled>
  );
}
