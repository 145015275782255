import React from "react";
import {
  AutocompleteInput,
  CloneButton,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  TextField,
} from "react-admin";

const Filters = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Call"
      source="call.eq"
      reference="calls"
      filterToQuery={(q) => q && { title: { ilike: q } }}
      shouldRenderSuggestions={(val) => {
        return val.trim().length > 2;
      }}
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput optionText="title.en" optionValue="id" />
    </ReferenceInput>
  </Filter>
);

export default function ListPage(props) {
  return (
    <List filters={<Filters />} {...props}>
      <Datagrid>
        <TextField source="id" />
        <ReferenceField label="Call" source="call" reference="calls">
          <TextField source="title.en" />
        </ReferenceField>
        <TextField label="Name" source="label.en" />
        <NumberField source="order" />
        <DateField source="createdAt" />
        <EditButton />
        <CloneButton />
      </Datagrid>
    </List>
  );
}
