import React from "react";
import { Edit } from "react-admin";
import Form from "./Form";

const Title = ({ record }) => (
  <span>Edit {record ? `"${record.label}"` : ""}</span>
);

const redirect = (basePath, id, data) => `/plans/${data.plan}/show`;
export default function EditForn(props) {
  return (
    <Edit title={<Title />} undoable={false} {...props}>
      <Form redirect={redirect} disablePlanInput disableFeatureInput />
    </Edit>
  );
}
