import React from "react";
import {
  Datagrid,
  List,
  TextField,
  ReferenceField,
  Filter,
  TextInput,
  ShowButton,
  EditButton,
} from "react-admin";

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="name.ilike" alwaysOn />
  </Filter>
);

export default function ListPage(props) {
  return (
    <List filters={<Filters />} {...props}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceField label="Plan" source="plan" reference="plans">
          <TextField source="name" />
        </ReferenceField>
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
}
