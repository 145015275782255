import { REL_TYPE_TO_ONE } from "./constants";

const voucher = {
  attributes: {
    id: { editable: false },
    code: { editable: true },
    type: { editable: true },
    value: { editable: true },
    usageLimit: { editable: true },
    expiresAt: { editable: true },
  },
  relationships: {
    call: {
      type: "calls",
      editable: ["create"],
      relation: REL_TYPE_TO_ONE,
    },
  },
};
export default voucher;
