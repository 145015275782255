import React, { useState } from "react";
import Progress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveIcon from "@material-ui/icons/Clear";
import OpenIcon from "@material-ui/icons/OpenInNew";
import FileIcon from "@material-ui/icons/InsertDriveFile";
import Typography from "@material-ui/core/Typography";

function isVideo(fileName?: string) {
  if (fileName === undefined) {
    return false;
  }
  return /\.(mp4|mov)$/gim.test(fileName);
}

function isImage(fileName?: string) {
  if (fileName === undefined) {
    return false;
  }
  return /\.(jpe?g|png|gif|svg|webp)$/gim.test(fileName);
}

function getFilenameFromUrl(url: string) {
  const match = /.*\/(.*)$/i.exec(url);
  if (match) {
    const [, fileName] = match;
    return decodeURIComponent(fileName);
  }
}

function AssetPreview({ src, fileName }: { src: string; fileName?: string }) {
  if (isImage(src) || isImage(fileName)) {
    return (
      <img
        style={{ objectFit: "contain", maxWidth: "100%", maxHeight: "100%" }}
        src={src}
        alt={src}
      />
    );
  }

  if (isVideo(src) || isVideo(fileName)) {
    return (
      <video
        src={src}
        style={{ maxWidth: "100%", maxHeight: "100%" }}
        controls
      />
    );
  }

  return (
    <>
      <FileIcon />
      <div style={{ padding: "10px" }}>
        <Typography variant="body2" align="center">
          {fileName || getFilenameFromUrl(src)}
        </Typography>
      </div>
    </>
  );
}

type PreviewProps = {
  src: string;
  progress?: number;
  fileName?: string;
  onDelete?: React.MouseEventHandler<HTMLButtonElement>;
  onRemove?: React.MouseEventHandler<HTMLButtonElement>;
};

export default function Preview({
  src,
  progress,
  fileName,
  onDelete,
  onRemove,
}: PreviewProps) {
  const [hover, setHover] = useState(false);
  return (
    <Paper
      style={{
        width: "200px",
        height: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        flexDirection: "column",
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <AssetPreview src={src} fileName={fileName} />

      {progress && (
        <Progress
          style={{
            position: "absolute",
            zIndex: 2,
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
          variant="determinate"
          value={progress}
        />
      )}
      {hover && (
        <div
          style={{
            position: "absolute",
            zIndex: 3,
            bottom: 0,
            left: 0,
            padding: 5,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            boxSizing: "border-box",
          }}
        >
          <a href={src} target="_blank" rel="noopener noreferrer">
            <Button
              aria-label="open"
              color="default"
              variant="contained"
              size="small"
            >
              <OpenIcon />
            </Button>
          </a>
          {onDelete && (
            <Button
              aria-label="delete"
              color="secondary"
              onClick={onDelete}
              variant="contained"
              size="small"
              style={{ color: "white", backgroundColor: "red" }}
            >
              <DeleteIcon />
            </Button>
          )}
          {onRemove && (
            <Button
              aria-label="remove"
              color="secondary"
              onClick={onRemove}
              variant="contained"
              size="small"
              style={{ color: "white", backgroundColor: "orange" }}
            >
              <RemoveIcon />
            </Button>
          )}
        </div>
      )}
    </Paper>
  );
}
