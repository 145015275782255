import SendIcon from "@material-ui/icons/Send";
import AddIcon from "@material-ui/icons/LibraryAdd";
import CancelIcon from "@material-ui/icons/Cancel";
import { Link } from "react-router-dom";
import { stringify } from "query-string";
import {
  AutocompleteInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
  SelectInput,
  SaveButton,
  Toolbar,
  Button,
} from "react-admin";

const omitIdAndEmail = ({ id, email, ...rest }) => rest;
const redirectOnClone = (basePath, id, data) =>
  `/invitations/create?${stringify({
    source: JSON.stringify(omitIdAndEmail(data)),
  })}`;

function CustomToolbar({ redirect, ...props }) {
  return (
    <Toolbar {...props}>
      <SaveButton label="Send" redirect={redirect} icon={<SendIcon />} />
      <SaveButton
        style={{ marginLeft: 20 }}
        label="Send & clone"
        redirect={redirectOnClone}
        submitOnEnter={false}
        variant="text"
        icon={<AddIcon />}
      />
      <Button
        style={{ marginLeft: 20 }}
        label="Cancel"
        component={Link}
        to={
          props.record && props.record.call
            ? `/calls/${props.record.call}/show`
            : "/invitations"
        }
      >
        <CancelIcon />
      </Button>
    </Toolbar>
  );
}

const isRequired = required();

export default function Form({ isNew, ...props }) {
  return (
    <SimpleForm
      {...props}
      toolbar={isNew ? <CustomToolbar redirect={props.redirect} /> : undefined}
    >
      <TextInput disabled source="id" />
      <ReferenceInput
        source="call"
        label="Call"
        reference="calls"
        filterToQuery={(q) => q && { title: { ilike: q } }}
        shouldRenderSuggestions={(val) => {
          return val.trim().length > 2;
        }}
        validate={[isRequired]}
      >
        <AutocompleteInput
          optionText="title.en"
          optionValue="id"
          options={{ disabled: props.isEdit }}
        />
      </ReferenceInput>
      <SelectInput
        source="scope"
        choices={[
          { id: "owner", name: "Owner" },
          { id: "manager", name: "Manager" },
          { id: "applicant", name: "Applicant" },
        ]}
        validate={[isRequired]}
      />
      <TextInput
        source="email"
        validate={[isRequired]}
        options={{ disabled: props.isEdit }}
      />
      <TextInput source="message" />
      <ReferenceInput
        source="issuer"
        label="Invited By"
        reference="users"
        filterToQuery={(q) => q && { email: { ilike: q } }}
        shouldRenderSuggestions={(val) => {
          return val.trim().length > 2;
        }}
      >
        <AutocompleteInput
          optionText={(choice) =>
            choice ? choice.email || choice.publicName || "" : ""
          }
          optionValue="id"
          options={{ disabled: props.isEdit }}
        />
      </ReferenceInput>
    </SimpleForm>
  );
}
