import { REL_TYPE_TO_ONE } from "./constants";

const wsSpaceUser = {
  attributes: {
    id: { editable: false },
    scope: { editable: true },
  },
  relationships: {
    space: {
      type: "ws-spaces",
      editable: true,
      relation: REL_TYPE_TO_ONE,
    },
    user: {
      type: "users",
      editable: true,
      relation: REL_TYPE_TO_ONE,
    },
  },
};
export default wsSpaceUser;
