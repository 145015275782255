import React from "react";
import {
  Show,
  TextField,
  ReferenceManyField,
  ReferenceField,
  Datagrid,
  EditButton,
  DeleteButton,
  SimpleShowLayout,
  Pagination,
} from "react-admin";
import AddFeatureButton from "./AddFeatureButton";

export default function ShowPage(props) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="type" />
        <TextField source="specialOfferCode" />
        <h2>Features</h2>
        <ReferenceManyField
          addLabel={false}
          reference="plan-product-features"
          target="plan"
          pagination={<Pagination />}
        >
          <Datagrid>
            <ReferenceField
              label="Feature"
              source="feature"
              reference="product-features"
            >
              <TextField source="label" />
            </ReferenceField>
            <EditButton />
            <DeleteButton redirect={false} />
          </Datagrid>
        </ReferenceManyField>
        <AddFeatureButton />
      </SimpleShowLayout>
    </Show>
  );
}
