import React from "react";
import {
  ArrayInput,
  SimpleFormIterator,
  AutocompleteInput,
  DateField,
  NumberInput,
  BooleanInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
  TextField,
  SelectInput,
} from "react-admin";

const isRequired = required();

export default function Form({ isNew, ...props }) {
  return (
    <SimpleForm {...props}>
      <TextInput disabled source="id" />
      <ReferenceInput
        source="call"
        label="Call"
        reference="calls"
        filterToQuery={(q) => q && { title: { ilike: q } }}
        shouldRenderSuggestions={(val) => {
          return val.trim().length > 2;
        }}
        validate={[isRequired]}
      >
        <AutocompleteInput
          optionText="title.en"
          optionValue="id"
          options={{ disabled: props.disableCallInput }}
        />
      </ReferenceInput>
      <SelectInput
        validate={[isRequired]}
        source="eventType"
        choices={[
          { id: "submission_started", name: "submission_started" },
          { id: "submission_finished", name: "submission_finished" },
          { id: "submission_edit_started", name: "submission_edit_started" },
          { id: "submission_edit_finished", name: "submission_edit_finished" },
          { id: "submission_cancelled", name: "submission_cancelled" },
        ]}
      />
      <TextInput source="url" validate={[isRequired]} />
      <BooleanInput source="active" />
      <NumberInput source="failedAttempts" disabled={isNew} />
      <NumberInput
        source="maxAttempts"
        helperText="Maximum number of failed attempts before webhook is disabled"
      />
      <ArrayInput
        label="Incident Emails"
        source="incidentEmails"
        helperText="These emails will be notified in case of automatic webhook deactivation"
      >
        <SimpleFormIterator disableReordering>
          <TextInput label="Email" source="" />
        </SimpleFormIterator>
      </ArrayInput>
      <DateField source="createdAt" />
      <DateField source="editedAt" />
      <TextField source="lastError" />
    </SimpleForm>
  );
}
