import { Create, CreateProps, Record } from "react-admin";
import Form from "./Form";

const redirect = (basePath: string, id: string, data: Record) =>
  `/calls/${data.call}/show`;
export default function CreateForm(props: CreateProps) {
  return (
    <Create {...props}>
      <Form redirect={redirect} />
    </Create>
  );
}
