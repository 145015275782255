import React from "react";
import { Edit } from "react-admin";
import Form from "./Form";
import transform from "./utils/transform";

const Title = ({ record }) => (
  <span>Edit {record ? `"${record.title.en}"` : ""}</span>
);

export default function EditForm(props) {
  return (
    <Edit title={<Title />} undoable={false} {...props} transform={transform}>
      <Form redirect={false} />
    </Edit>
  );
}
