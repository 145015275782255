import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  ReferenceField,
  ReferenceInput,
  Filter,
  AutocompleteInput,
  TextInput,
  BooleanInput,
} from "react-admin";

const Filters = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Call"
      source="call.eq"
      reference="calls"
      filterToQuery={(q) => q && { title: { ilike: q } }}
      shouldRenderSuggestions={(val) => {
        return val.trim().length > 2;
      }}
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput optionText="title.en" optionValue="id" />
    </ReferenceInput>
    <TextInput label="Email" source="email.eq" alwaysOn />
    <BooleanInput label="Submitted" source="submitted.eq" />
    <BooleanInput label="In Edit" source="inEdit.eq" />
  </Filter>
);
export default function ListPage(props) {
  return (
    <List filters={<Filters />} {...props}>
      <Datagrid rowClick="show">
        <TextField source="key" />
        <ReferenceField label="Contest" source="call" reference="calls">
          <TextField source="title.en" />
        </ReferenceField>
        <ReferenceField label="Owner" source="owner" reference="users">
          <TextField source="publicName" />
        </ReferenceField>
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <BooleanField source="isCoordinated" />
        <BooleanField source="preconditionCompleted" />
        <TextField source="status" />
      </Datagrid>
    </List>
  );
}
