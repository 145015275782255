import {
  CloneButton,
  Datagrid,
  EditButton,
  List,
  ListProps,
  ReferenceField,
  TextField,
} from "react-admin";

export default function ListPage(props: ListProps) {
  return (
    <List {...props}>
      <Datagrid>
        <ReferenceField label="Call" source="call" reference="calls">
          <TextField source="title.en" />
        </ReferenceField>
        <EditButton />
        <CloneButton />
      </Datagrid>
    </List>
  );
}
