import { REL_TYPE_TO_ONE } from "./constants";

const planProductFeature = {
  attributes: {
    id: { editable: false },
    config: { editable: true },
  },
  relationships: {
    plan: {
      type: "plans",
      editable: true,
      relation: REL_TYPE_TO_ONE,
    },
    feature: {
      type: "product-features",
      editable: true,
      relation: REL_TYPE_TO_ONE,
    },
  },
};
export default planProductFeature;
