import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useInput, Labeled, Button, InputProps } from "react-admin";
import Uppy, { UppyFile } from "@uppy/core";
import S3 from "@uppy/aws-s3";
import { useUppy } from "@uppy/react";
import { Immutable, produce } from "immer";
import { useForm } from "react-final-form";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import UploadIcon from "@material-ui/icons/Publish";
import throttle from "lodash/throttle";

import Preview from "./Preview";
import getUploadParameters from "./get-upload-parameters";

function formatUrl(url: string) {
  const fileUrl = new URL(url);
  const filePath = fileUrl.pathname;
  return `${process.env.REACT_APP_ASSET_CDN}${filePath}`;
}

function parseUrl(url: string) {
  return url;
}

type UploadingFile = {
  id: string;
  name: string;
  preview: string;
  progress?: number;
};

type UploadingFileStore = Immutable<{
  [key: string]: UploadingFile;
}>;

type FileUploaderProps = InputProps & {
  accept: string;
  format?: (value: string) => string;
  parse?: (value: string) => string;
  canDelete?: boolean;
};
export default function FileUploader({
  accept,
  format = formatUrl,
  parse = parseUrl,
  label,
  canDelete = false,
  ...props
}: FileUploaderProps) {
  const {
    input: { name, value },
  } = useInput(props);
  const { change } = useForm();
  const [uploadingFiles, setUploadingFiles] = useState<UploadingFileStore>({});
  const uppy = useUppy(() => {
    return Uppy<Uppy.StrictTypes>({ autoProceed: true }).use(S3, {
      getUploadParameters,
    });
  });

  const onDrop = useCallback(
    (acceptedFiles) => {
      const upload = (file: File) => {
        const fileId = uppy.addFile({
          name: file.name,
          type: file.type,
          data: file,
          size: file.size,
        });
        setUploadingFiles(
          produce((draft) => {
            draft[fileId] = {
              id: fileId,
              name: file.name,
              preview: URL.createObjectURL(file),
            };
          })
        );
      };

      upload(acceptedFiles[0]);
    },
    [uppy]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: accept,
    multiple: false,
  });

  const removeFile = useCallback(async () => {
    change(name, null);
  }, [change, name]);

  const deleteFile = useCallback(
    async (url: string) => {
      change(name, null);

      await fetch(
        `${
          process.env.REACT_APP_API_URL
        }/admin/uploads?url=${encodeURIComponent(url)}`,
        {
          method: "delete",
          // Send and receive JSON.
          headers: {
            accept: "application/json",
            "content-type": "application/json",
          },
          credentials: "include",
        }
      );
    },
    [change, name]
  );

  const cancelUpload = useCallback(
    (fileId: string) => {
      uppy.removeFile(fileId);
      setUploadingFiles(
        produce((draft) => {
          URL.revokeObjectURL(draft[fileId].preview);
          delete draft[fileId];
        })
      );
    },
    [uppy]
  );

  const addFile = useCallback(
    (src: string) => {
      change(name, src);
    },
    [change, name]
  );

  useEffect(() => {
    const onProgress = throttle(
      (
        file: UppyFile,
        progress: { bytesUploaded: number; bytesTotal: number }
      ) => {
        const progressValue = Math.round(
          (progress.bytesUploaded / progress.bytesTotal) * 100
        );
        setUploadingFiles(
          produce((draft) => {
            // Since onProgress is throttled, it's possible
            // that onProgress is fired after the upload
            // had been finished (which would re-add the file
            // to the list of uploadingFiles). So we must only
            // update the progress of a file, if it's still in
            // that list.
            if (draft[file.id]) {
              draft[file.id].progress = progressValue;
            }
          })
        );
      },
      500
    );
    const onSuccess = (file: UppyFile, response: { uploadURL: string }) => {
      addFile(format(response.uploadURL));
      // Remove file from Uppy to allow re-uploading
      // the same file again in the future.
      uppy.removeFile(file.id);
      // Remove file from uploadingFiles list
      setUploadingFiles(
        produce((draft) => {
          URL.revokeObjectURL(draft[file.id].preview);
          delete draft[file.id];
        })
      );
    };
    uppy.on("upload-success", onSuccess);
    uppy.on("upload-progress", onProgress);
    return () => {
      uppy.off("upload-success", onSuccess);
      uppy.off("upload-progress", onProgress);
    };
  }, [uppy, addFile, format]);

  return (
    <Labeled label={label || name}>
      <div>
        {!value && Object.entries(uploadingFiles).length === 0 && (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Paper
              style={{
                maxWidth: "350px",
                minWidth: "256px",
                boxSizing: "border-box",
                padding: 20,
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                backgroundColor: isDragActive ? "#DBEAFE" : "transparent",
              }}
            >
              <>
                <Typography variant="body2" align="center">
                  {isDragActive ? (
                    <>Drop the file here ...</>
                  ) : (
                    <>Drag 'n' drop file here</>
                  )}
                </Typography>
                <Button
                  alignIcon="right"
                  variant="outlined"
                  label="Add file"
                  style={{ marginTop: "5px" }}
                >
                  <UploadIcon />
                </Button>
              </>
            </Paper>
          </div>
        )}
        {value && (
          <Preview
            src={parse(value)}
            onDelete={canDelete ? () => deleteFile(parse(value)) : undefined}
            onRemove={removeFile}
          />
        )}
        {Object.entries(uploadingFiles).map(([id, file]) => (
          <div key={file.id}>
            <Preview
              src={file.preview}
              fileName={file.name}
              progress={file.progress}
              onDelete={() => cancelUpload(file.id)}
            />
          </div>
        ))}
      </div>
    </Labeled>
  );
}
