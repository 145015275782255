import { REL_TYPE_TO_ONE } from "./constants";

const organisationProductFeature = {
  attributes: {
    id: { editable: false },
    config: { editable: true },
  },
  relationships: {
    organisation: {
      type: "organisations",
      editable: true,
      relation: REL_TYPE_TO_ONE,
    },
    feature: {
      type: "product-features",
      editable: true,
      relation: REL_TYPE_TO_ONE,
    },
  },
};
export default organisationProductFeature;
