const ad = {
  attributes: {
    id: { editable: false },
    adSlot: { editable: true },
    locale: { editable: true },
    url: { editable: true },
    campaign: { editable: true },
    active: { editable: true },
    from: { editable: true },
    to: { editable: true },
    content: { editable: true },
    name: { editable: true },
    geoInclude: { editable: true },
    geoExclude: { editable: true },
    priority: { editable: true },
    weight: { editable: true },
    impressionUrl: { editable: false },
    clickUrl: { editable: false },
  },
  relationships: {},
};
export default ad;
