import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  NumberField,
  BooleanField,
  ReferenceField,
  TopToolbar,
} from "react-admin";
import RevokeSubmission from "./actions/RevokeSubmission";

const SubmissionActions = ({ data }) => {
  return (
    <TopToolbar>{data && <RevokeSubmission resource={data} />}</TopToolbar>
  );
};

export default function ShowPage(props) {
  return (
    <Show actions={<SubmissionActions />} {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="key" />
        <ReferenceField label="Contest" source="call" reference="calls">
          <TextField source="title.en" />
        </ReferenceField>
        <ReferenceField label="Owner" source="owner" reference="users">
          <TextField source="publicName" />
        </ReferenceField>
        <ReferenceField label="Email" source="owner" reference="users">
          <TextField source="email" />
        </ReferenceField>
        <TextField source="status" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <NumberField source="index" />
        <BooleanField source="submitted" />
        <BooleanField source="inEdit" />
        <BooleanField source="isCoordinated" />
        <TextField source="preconditionFormValues" />
        <BooleanField source="preconditionCompleted" />
        <BooleanField source="customNewsletterSignup1" />
        <BooleanField source="customNewsletterSignup2" />
      </SimpleShowLayout>
    </Show>
  );
}
