import React from "react";
import { Link } from "react-router-dom";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "react-admin";

const styles = {
  button: {
    marginTop: "1em",
  },
};

const AddUserButton = ({ classes, record }) => (
  <Button
    className={classes.button}
    variant="raised"
    component={Link}
    to={{
      pathname: `/ws-space-users/create`,
      state: {
        record: { space: record.id },
      },
    }}
    label="Add a user"
    title="Add a user"
  >
    <PersonAddIcon />
  </Button>
);

export default withStyles(styles)(AddUserButton);
