import { REL_TYPE_TO_ONE } from "./constants";

const callPrecondition = {
  attributes: {
    id: { editable: false },
    title: { editable: true },
    headline: { editable: true },
    info: { editable: true },
    actionLabel: { editable: true },
    formSchema: { editable: true },
  },
  relationships: {
    call: {
      type: "calls",
      editable: ["create"],
      relation: REL_TYPE_TO_ONE,
    },
  },
};
export default callPrecondition;
