import React from "react";
import { required, SimpleForm, TextInput } from "react-admin";

const isRequired = required();

export default function Form(props) {
  return (
    <SimpleForm {...props}>
      <TextInput disabled source="id" />
      <TextInput source="label" validate={[isRequired]} />
      <TextInput source="name" validate={[isRequired]} />
      <TextInput disabled source="createdAt" />
      <TextInput disabled source="updatedAt" />
    </SimpleForm>
  );
}
