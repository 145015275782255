import { ChangeEvent, useState, useCallback } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";

export type RestrictValue = {
  name: "countryResidence" | "countryOrigin" | "gender";
  valid: string[];
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getInitialRestrictionValue = (value?: RestrictValue | string) => {
  if (value === undefined || typeof value === "string") {
    return [];
  }
  return value.valid;
};

function renderSelectedCountries(value: unknown) {
  if (Array.isArray(value)) {
    return value.join(", ");
  }
}

export default function RestrictRequirement({
  label,
  name,
  value,
  onChange,
  options,
}: {
  label: string;
  name: RestrictValue["name"];
  value?: RestrictValue | string;
  onChange: (name: string, value: boolean | RestrictValue) => void;
  options: { label: string; value: string }[];
}) {
  const [restrictions, setRestrictions] = useState<string[]>(
    getInitialRestrictionValue(value)
  );
  const changeRestrictions = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const newRestrictions = event.target.value as string[];
      setRestrictions(newRestrictions);
      if (value !== undefined) {
        if (newRestrictions.length === 0) {
          return onChange(name, true);
        }
        return onChange(name, { name, valid: newRestrictions });
      }
    },
    [value, onChange, name]
  );
  const changeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
      const newValue =
        restrictions.length > 0 ? { name, valid: restrictions } : true;
      onChange(name, isChecked ? newValue : false);
    },
    [onChange, name, restrictions]
  );

  return (
    <>
      <FormControlLabel
        htmlFor={name}
        key={name}
        control={
          <Checkbox
            checked={Boolean(value)}
            id={name}
            color="primary"
            onChange={changeHandler}
            value={name}
          />
        }
        label={label}
      />
      {Boolean(value) && (
        <FormControl style={{ marginLeft: 30 }}>
          <InputLabel id={`country-input-${name}-label`}>Allowed</InputLabel>
          <Select
            labelId={`country-input-${name}-label`}
            id={`country-input-${name}`}
            multiple
            value={restrictions}
            onChange={changeRestrictions}
            input={<Input />}
            renderValue={renderSelectedCountries}
            MenuProps={MenuProps}
          >
            {options.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                <Checkbox checked={restrictions.indexOf(value) > -1} />
                <ListItemText primary={label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
}
