import pick from "lodash/pick";
import fromPairs from "lodash/fromPairs";
import kebabCaseKeys from "kebabcase-keys";

/**
 * Converts relational data (e.g. a single id or an array of ids) to a JSON:API
 * spec compliant relationship data object.
 *
 * E.g. ('123', 'users') -> { id: '123', type: 'users' }
 *
 * @param {[String]|String} relationshipData Single id or array of ids or related data
 * @param {String} resourceType Resource type of relationship
 */
const serializeRelationshipData = (relationshipData, resourceType) => {
  if (Array.isArray(relationshipData)) {
    return relationshipData.map((id) => ({
      id,
      type: resourceType,
    }));
  }

  return relationshipData ? { id: relationshipData, type: resourceType } : null;
};

export default function serialize(schema, resourceType, data, id, action) {
  // get names of fields, that can be edited (defined in the schema)
  const editableFields = Object.entries(schema.attributes)
    .filter(([key, value]) =>
      Array.isArray(value.editable)
        ? value.editable.includes(action)
        : value.editable
    )
    .map(([key]) => key);

  // get names of relationships, that can be edited (defined in the schema)
  const editableRelationships = Object.entries(schema.relationships)
    .filter(([key, value]) =>
      Array.isArray(value.editable)
        ? value.editable.includes(action)
        : value.editable
    )
    .map(([key]) => key);

  // serialize relationship data correctly
  const rawRelationshipEntries = Object.entries(
    pick(data, editableRelationships)
  ).map(([key, data]) => [
    key,
    { data: serializeRelationshipData(data, schema.relationships[key].type) },
  ]);

  return {
    data: {
      id,
      type: resourceType,
      attributes: kebabCaseKeys(pick(data, editableFields), { deep: true }),
      relationships: kebabCaseKeys(fromPairs(rawRelationshipEntries), {
        deep: true,
      }),
    },
  };
}
