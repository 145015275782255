import React from "react";
import { Edit } from "react-admin";
import Form from "./Form";

const Title = ({ record }) => (
  <span>Edit {record ? `"${record.id}"` : ""}</span>
);

const redirect = (basePath, id, data) => `/calls/${data.call}/show`;

export default function EditForn(props) {
  return (
    <Edit title={<Title />} undoable={false} {...props}>
      <Form redirect={redirect} disableCallInput />
    </Edit>
  );
}
