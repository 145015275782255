import React from "react";
import {
  Datagrid,
  List,
  TextField,
  BooleanField,
  ReferenceField,
  DateField,
} from "react-admin";

export default function ListPage(props) {
  return (
    <List {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <ReferenceField
          label="Organisation"
          source="organisation"
          reference="organisations"
        >
          <TextField source="name.en" />
        </ReferenceField>
        <BooleanField source="active" />
        <DateField source="lastUsage" />
      </Datagrid>
    </List>
  );
}
