import {
  AutocompleteInput,
  ReferenceInput,
  required,
  TabbedForm,
  TextInput,
  FormTab,
  SelectInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";

import FileUploader from "../../components/FileUploader";
import CSVInput from "./CSVInput";

function urlToPath(url: string) {
  const fileUrl = new URL(url);
  return fileUrl.pathname;
}

function pathToUrl(filePath: string) {
  return `${process.env.REACT_APP_ASSET_CDN}${filePath}`;
}

const isRequired = required();

export default function Form({ disableCallInput = false, ...props }) {
  const websiteLink = `https://site.picter.com/${props?.record?.slug}`;

  return (
    <TabbedForm {...props}>
      <FormTab label="General">
        {props?.record?.slug && <a href={websiteLink}>{websiteLink}</a>}

        <TextInput disabled source="id" />
        <TextInput disabled source="slug" />
        <ReferenceInput
          source="call"
          label="Call"
          reference="calls"
          filterToQuery={(q) => q && { title: { ilike: q } }}
          shouldRenderSuggestions={(val: string) => {
            return val.trim().length > 2;
          }}
          validate={[isRequired]}
        >
          <AutocompleteInput
            optionText="title.en"
            optionValue="id"
            // @ts-ignore
            options={{ disabled: disableCallInput }}
          />
        </ReferenceInput>
        <FileUploader
          source="headerImage"
          label="Header image (format 4:3)"
          accept="image/*"
          format={urlToPath}
          parse={pathToUrl}
        />
        <TextInput source="headerImageCaption" />
        <TextInput source="brandColor" />
        <TextInput source="language" validate={[isRequired]} />
      </FormTab>

      <FormTab label="About">
        <ArrayInput source="about">
          <SimpleFormIterator>
            <SelectInput
              source="type"
              label="Block type"
              choices={[
                { id: "text", name: "Text" },
                { id: "image", name: "Image" },
                { id: "gallery", name: "Gallery" },
              ]}
            />
            <FormDataConsumer>
              {({ scopedFormData, getSource: originalGetSource }) => {
                const getSource = (source: string) =>
                  originalGetSource?.(source) || source;

                switch (scopedFormData?.type) {
                  case "text":
                    return (
                      <TextInput
                        multiline
                        label="Content"
                        source={getSource("content")}
                        fullWidth
                      />
                    );
                  case "image":
                    return (
                      <>
                        <FileUploader
                          source={getSource("content.src")}
                          label="Image"
                          accept="image/*"
                          format={urlToPath}
                          parse={pathToUrl}
                        />
                        <TextInput
                          label="Caption"
                          source={getSource("content.caption")}
                        />
                        <TextInput
                          label="Description"
                          source={getSource("content.description")}
                        />
                      </>
                    );
                  case "gallery":
                    return (
                      <>
                        <TextInput
                          label="Title"
                          source={getSource("content.title")}
                        />
                        <TextInput
                          label="Description"
                          source={getSource("content.description")}
                        />
                        <ArrayInput
                          label="Files"
                          source={getSource("content.files")}
                        >
                          <SimpleFormIterator>
                            <FileUploader
                              source="src"
                              label="Image"
                              accept="image/*"
                              format={urlToPath}
                              parse={pathToUrl}
                            />
                            <TextInput label="Caption" source="caption" />
                            <TextInput
                              label="Description"
                              source="description"
                            />
                          </SimpleFormIterator>
                        </ArrayInput>
                      </>
                    );
                }
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      <FormTab label="jury">
        <TextInput label="Section Title" source="jurySectionTitle" />
        <CSVInput
          source="jury"
          parse={(jurors) => ({ jurors })}
          description={`Import a CSV file containing jurors with the columns "name", "jobTitle" and "bio". You can import multiple files (one after another) and it will add a new jury for each file.`}
        />
        <ArrayInput source="jury">
          <SimpleFormIterator>
            <TextInput label="Title" source="title" />
            <ArrayInput label="Jurors" source="jurors">
              <SimpleFormIterator>
                <FileUploader
                  source="avatar"
                  label="Avatar"
                  accept="image/*"
                  format={urlToPath}
                  parse={pathToUrl}
                />
                <TextInput label="Name" source="name" />
                <TextInput label="Job title" source="jobTitle" />
                <TextInput label="Bio" source="bio" />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      <FormTab label="FAQ">
        <CSVInput
          source="faq"
          description={`Import a CSV file containing FAQs with the columns "question" and "answer". Please note: Imports will override all existing entries.`}
          override
        />
        <ArrayInput source="faq">
          <SimpleFormIterator>
            <TextInput label="Question" source="question" fullWidth />
            <TextInput label="Answer" source="answer" multiline fullWidth />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  );
}
