import { REL_TYPE_TO_ONE } from "./constants";

const callWebhook = {
  attributes: {
    id: { editable: false },
    eventType: { editable: true },
    url: { editable: true },
    active: { editable: true },
    failedAttempts: { editable: ["update"] },
    maxAttempts: { editable: true },
    incidentEmails: { editable: true },
  },
  relationships: {
    call: {
      type: "calls",
      editable: ["create"],
      relation: REL_TYPE_TO_ONE,
    },
  },
};
export default callWebhook;
