import React from "react";
import { Create } from "react-admin";
import Form from "./Form";
import transform from "./utils/transform";

export default function CreateForm(props) {
  return (
    <Create {...props} transform={transform}>
      <Form isNew redirect="show" />
    </Create>
  );
}
