export const profileFields: {
  /**
   * Label of the field, used in checkboxes and other UI elements
   */
  label: string;
  /**
   * Value of the field, used in the API
   */
  value: string;
  /**
   * A simple requirement field can just be required. A complex requirement field is an object with a `name` property
   * and other properties that are needed to define a complex requirement (e.g. `gender` with `valid` options).
   * We distinguish between simple and complex requirements to be able to handle them differently in the UI.
   */
  isSimpleRequirement?: boolean;
  /**
   * If the field can be hidden in the submission flow
   */
  canBeHidden: boolean;
  /**
   * If the field can be required in the submission flow
   */
  canBeRequired: boolean;
}[] = [
  {
    label: "Name",
    value: "name",
    isSimpleRequirement: true,
    canBeHidden: false,
    canBeRequired: true,
  },
  {
    label: "First name",
    value: "firstName",
    isSimpleRequirement: true,
    canBeHidden: false,
    canBeRequired: true,
  },
  {
    label: "Last name",
    value: "lastName",
    isSimpleRequirement: true,
    canBeHidden: false,
    canBeRequired: true,
  },
  {
    label: "Artist name",
    value: "artistName",
    isSimpleRequirement: true,
    canBeHidden: true,
    canBeRequired: false,
  },
  { label: "Gender", value: "gender", canBeHidden: true, canBeRequired: true },
  {
    label: "Date of birth",
    value: "dateOfBirth",
    canBeHidden: true,
    canBeRequired: true,
  },
  {
    label: "Address",
    value: "address",
    isSimpleRequirement: true,
    canBeHidden: true,
    canBeRequired: true,
  },
  {
    label: "Country Residence",
    value: "countryResidence",
    isSimpleRequirement: false,
    canBeHidden: true,
    canBeRequired: true,
  },
  {
    label: "Country Origin",
    value: "countryOrigin",
    isSimpleRequirement: false,
    canBeHidden: true,
    canBeRequired: true,
  },
  {
    label: "Bio",
    value: "bioLong",
    isSimpleRequirement: true,
    canBeHidden: true,
    canBeRequired: true,
  },
  {
    label: "CV",
    value: "cv",
    isSimpleRequirement: true,
    canBeHidden: true,
    canBeRequired: true,
  },
  {
    label: "Phone",
    value: "phone",
    isSimpleRequirement: true,
    canBeHidden: true,
    canBeRequired: true,
  },
  {
    label: "Company name",
    value: "companyName",
    isSimpleRequirement: true,
    canBeHidden: true,
    canBeRequired: true,
  },
  {
    label: "Profession",
    value: "profession",
    isSimpleRequirement: true,
    canBeHidden: true,
    canBeRequired: true,
  },
  {
    label: "Website",
    value: "website",
    isSimpleRequirement: true,
    canBeHidden: true,
    canBeRequired: true,
  },
  {
    label: "Represented by agency",
    value: "representedByAgency",
    isSimpleRequirement: true,
    canBeHidden: true,
    canBeRequired: true,
  },
  {
    label: "Represented by gallery",
    value: "representedByGallery",
    isSimpleRequirement: true,
    canBeHidden: true,
    canBeRequired: true,
  },
  {
    label: "Facebook",
    value: "facebook",
    isSimpleRequirement: true,
    canBeHidden: true,
    canBeRequired: true,
  },
  {
    label: "Instagram",
    value: "instagram",
    isSimpleRequirement: true,
    canBeHidden: true,
    canBeRequired: true,
  },
  {
    label: "Twitter",
    value: "twitter",
    isSimpleRequirement: true,
    canBeHidden: true,
    canBeRequired: true,
  },
];
