import React from "react";
import {
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

const isRequired = required();

export default function Form(props) {
  return (
    <SimpleForm {...props}>
      <TextInput disabled source="id" />
      <TextInput label="Name" source="name.en" validate={[isRequired]} />
      <TextInput multiline label="Description" source="description.en" />
      <TextInput label="Address" source="address.line1" />
      <TextInput label="Address (line 2)" source="address.line2" />
      <TextInput label="City" source="address.city" />
      <TextInput label="Zip code" source="address.zip" />
      <TextInput label="Country" source="address.country" />
      <TextInput source="slug" validate={[isRequired]} />
      <TextInput type="url" source="website" />
      <TextInput disabled source="stripeUserId" />
      <ReferenceInput
        source="plan"
        label="Plan"
        reference="plans"
        validate={[isRequired]}
      >
        <SelectInput optionValue="id" optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  );
}
