import { useState } from "react";
import {
  Edit,
  Toolbar,
  SaveButton,
  EditProps,
  ToolbarProps,
  TitleProps,
  Button,
  Confirm,
  useMutation,
  useRefresh,
  useNotify,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import ActionDelete from "@material-ui/icons/Delete";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import Typography from "@material-ui/core/Typography";
import Form from "./Form";

function Title({ record }: TitleProps) {
  return <span>Edit {record ? `"${record.publicName}"` : ""}</span>;
}

const useButtonStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
}));

const useToolbarStyles = makeStyles({
  toolbar: {
    display: "flex",
    columnGap: "8px",
    "& :last-child": {
      marginLeft: "auto",
    },
  },
});

function EditToolbar(props: ToolbarProps) {
  const { pristine, record } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteInDays, setDeleteInDays] = useState(7);
  const refresh = useRefresh();
  const notify = useNotify();
  const [scheduleUserDeactivation] = useMutation(
    {
      type: "delete",
      resource: "users",
      payload: { id: record.id, query: { deleteIn: deleteInDays } },
    },
    {
      onSuccess: () => {
        setIsModalOpen(false);
        notify("User scheduled for deactivation", { type: "success" });
        refresh();
      },
      onFailure: () => {
        notify("Error: failed", { type: "error" });
      },
    }
  );

  return (
    <Toolbar {...props} classes={useToolbarStyles()}>
      <SaveButton disabled={pristine} />
      <Button
        classes={useButtonStyles(props)}
        label="Delete"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <ActionDelete />
      </Button>
      <Confirm
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={scheduleUserDeactivation}
        title="Want to delete the user?"
        content={
          <Typography variant="body1">
            Deactivate in{" "}
            <input
              style={{ width: "50px" }}
              type="number"
              min={0}
              value={deleteInDays}
              onChange={(e) => setDeleteInDays(parseInt(e.target.value, 10))}
            />{" "}
            days.
          </Typography>
        }
      />
    </Toolbar>
  );
}

export default function EditForn(props: EditProps) {
  return (
    <Edit title={<Title />} undoable={false} {...props}>
      <Form redirect={false} toolbar={<EditToolbar />} />
    </Edit>
  );
}
