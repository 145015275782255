import React from "react";
import { Edit } from "react-admin";
import Form from "./Form";

const Title = ({ record }) => (
  <span>Edit {record ? `"${record.name}"` : ""}</span>
);

export default function EditForn(props) {
  return (
    <Edit title={<Title />} undoable={false} {...props}>
      <Form redirect={false} />
    </Edit>
  );
}
