import { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { showNotification, Button } from "react-admin";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { MenuItem } from "@material-ui/core";
import { useFormik } from "formik";

const styles = (theme: any) => ({
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  button: {
    marginRight: theme.spacing.unit * 2,
  },
});

function AutoFinishSubmissions({
  resource,
  showNotification,
  classes,
}: {
  resource: { id: string };
  showNotification: Function;
  classes: any;
}) {
  const [revokeModalOpen, toggleRevokeModal] = useState(false);
  const formik = useFormik({
    initialValues: {
      dryRun: true,
      sendSuccessEmails: true,
      sendFailureEmails: true,
    },
    onSubmit: async (values) => {
      try {
        const result = await fetch(
          `${process.env.REACT_APP_API_URL}/admin/calls/${resource.id}/auto-finish-submissions`,
          {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(values),
          }
        );
        if (result.status === 201) {
          const { failedSubmissions, successfulSubmissions } =
            (await result.json()) as {
              failedSubmissions: string[];
              successfulSubmissions: string[];
            };
          console.log({ failedSubmissions, successfulSubmissions });
          showNotification(
            `${successfulSubmissions.length} submissions auto-finished, ${
              failedSubmissions.length
            } failed ${values.dryRun ? "(dry run)" : ""}`
          );
          toggleRevokeModal(false);
        } else {
          const body = await result.json();
          showNotification(
            `Error: submissions could not be auto-finished – ${body.message}`,
            "warning"
          );
          toggleRevokeModal(false);
        }
      } catch (e) {
        console.error(e);
        showNotification("Error: auto-finish failed", "warning");
      }
    },
  });

  return (
    <>
      <MenuItem color="primary" onClick={() => toggleRevokeModal(true)}>
        Auto-finish submissions
      </MenuItem>
      <Modal open={revokeModalOpen} onClose={() => toggleRevokeModal(false)}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "500px",
          }}
        >
          <Paper className={classes.paper}>
            <Typography id="modal-title" gutterBottom variant="h5">
              Auto-finish submissions
            </Typography>
            <Typography
              variant="body1"
              id="simple-modal-description"
              gutterBottom
            >
              Are you sure, you want to auto-finish all submissions that are
              still in edit?
            </Typography>

            <form onSubmit={formik.handleSubmit}>
              <FormGroup>
                <FormControlLabel
                  label="Dry run (no submissions will actually be auto-finished)"
                  control={
                    <Checkbox
                      checked={formik.values.dryRun}
                      onChange={formik.handleChange}
                      name="dryRun"
                      id="dryRun"
                    />
                  }
                />
                <FormControlLabel
                  label="Send confirmation emails on success"
                  control={
                    <Checkbox
                      checked={formik.values.sendSuccessEmails}
                      onChange={formik.handleChange}
                      name="sendSuccessEmails"
                      id="sendSuccessEmails"
                    />
                  }
                />
                <FormControlLabel
                  label="Send failure notification emails on failure"
                  control={
                    <Checkbox
                      checked={formik.values.sendFailureEmails}
                      onChange={formik.handleChange}
                      name="sendFailureEmails"
                      id="sendFailureEmails"
                    />
                  }
                />
              </FormGroup>
              <Button
                color="primary"
                type="submit"
                className={classes.button}
                label="Confirm"
              />
              <Button
                variant="contained"
                color="secondary"
                type="button"
                onClick={() => toggleRevokeModal(false)}
                className={classes.button}
                label="Cancel"
              />
            </form>
          </Paper>
        </div>
      </Modal>
    </>
  );
}

export default withStyles(styles)(
  connect(null, {
    showNotification,
  })(AutoFinishSubmissions)
);
