import { REL_TYPE_TO_ONE } from "./constants";

const picterApiKeys = {
  attributes: {
    id: { editable: false },
    key: { editable: false },
    active: { editable: true },
  },
  relationships: {
    organisation: {
      type: "organisations",
      editable: ["create"],
      relation: REL_TYPE_TO_ONE,
    },
  },
};
export default picterApiKeys;
