import React from "react";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { Edit, Toolbar, SaveButton, DeleteButton } from "react-admin";
import Form from "./Form";
import transform from "./utils/transform";

const Title = ({ record }) => (
  <span>Edit {record ? `"${record.id}"` : ""}</span>
);

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    columnGap: "8px",
    "& :last-child": {
      marginLeft: "auto",
    },
  },
});

const EditActions = (props) => {
  const { record, pristine } = props;
  const previewURL = `${process.env.REACT_APP_PUBLIC_ADS_APP_URL}/preview?id=${record.id}`;
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton disabled={pristine} />
      <Tooltip
        title={pristine ? "View ad preview" : "Save to enable preview"}
        style={{ pointerEvents: "auto" }}
      >
        <Button
          disabled={!pristine}
          color="primary"
          onClick={() => window.open(previewURL, "_blank").focus()}
        >
          Preview
        </Button>
      </Tooltip>
      <DeleteButton mutationMode="pessimistic" />
    </Toolbar>
  );
};

export default function EditForm(props) {
  return (
    <Edit title={<Title />} undoable={false} transform={transform} {...props}>
      <Form redirect={false} toolbar={<EditActions />} />
    </Edit>
  );
}
