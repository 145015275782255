import { REL_TYPE_TO_ONE } from "./constants";

const user = {
  attributes: {
    id: { editable: false },
    email: { editable: false },
    firstName: { editable: true },
    lastName: { editable: true },
    artistName: { editable: true },
    countryOrigin: { editable: true },
    countryResidence: { editable: true },
    address: { editable: true },
    phone: { editable: true },
    bioLong: { editable: true },
    website: { editable: true },
    emailVerified: { editable: true },
    newsletter: { editable: false },
    profession: { editable: true },
    companyName: { editable: true },
  },
  relationships: {
    plan: {
      type: "plans",
      editable: ["create", "update"],
      relation: REL_TYPE_TO_ONE,
    },
  },
};
export default user;
