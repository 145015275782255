const slots = [
  { id: "Contest Grid Card", name: "Contest Grid Card" },
  { id: "Contest Grid Banner", name: "Contest Grid Banner" },
  { id: "Login Screen", name: "Login Screen" },
  { id: "Submission Complete Page", name: "Submission Complete Page" },
  {
    id: "Submission Complete Email",
    name: "Submission Complete Email",
  },
  {
    id: "Newsletter",
    name: "Newsletter",
  },
  {
    id: "Dedicated Newsletter",
    name: "Dedicated Newsletter",
  },
];

export default slots;
