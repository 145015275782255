import React from "react";
import { Create } from "react-admin";
import Form from "./Form";
import transform from "./utils/transform";

export default function CreateForm(props) {
  return (
    <Create transform={transform} {...props}>
      <Form isNew />
    </Create>
  );
}
