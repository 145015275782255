import { Edit, EditProps, Record } from "react-admin";
import Form from "./Form";

const Title = () => <span>Edit call user</span>;

const redirect = (basePath: string, id: string, data: Record) =>
  `/calls/${data.call}/show`;

export default function EditForn(props: EditProps) {
  return (
    <Edit title={<Title />} undoable={false} {...props}>
      <Form redirect={redirect} disableCallInput disableUserInput />
    </Edit>
  );
}
