import * as React from "react";
import { Route } from "react-router-dom";
import ApplyCustomFieldLabelsAction from "./resources/calls/actions/ApplyCustomFieldLabelsAction";

const customRoutes = [
  <Route
    exact
    path="/calls/:id/apply-custom-field-labels"
    component={ApplyCustomFieldLabelsAction}
  />,
];

export default customRoutes;
