import React from "react";
import {
  required,
  SimpleForm,
  TextInput,
  DateInput,
  NumberInput,
  SelectInput,
  ReferenceInput,
} from "react-admin";
import Typography from "@material-ui/core/Typography";

const isRequired = required();

export default function Form(props) {
  return (
    <SimpleForm {...props}>
      <TextInput disabled source="id" />
      <TextInput source="name" validate={[isRequired]} />
      <ReferenceInput
        filter={{ type: { eq: "space" } }}
        source="plan"
        label="Plan"
        reference="plans"
      >
        <SelectInput optionValue="id" optionText="name" />
      </ReferenceInput>
      <TextInput source="phone" />
      <NumberInput source="quantitySeats" />
      <NumberInput
        source="quantitySeatsMin"
        label="Min quantity of billable seats"
      />
      <Typography
        variant="body2"
        style={{ minWidth: "500px", marginBottom: 20 }}
      >
        <span role="img" aria-label="point up">
          👆
        </span>{" "}
        For special deals with a minimum volume of billable seats, you can
        define this minimum here. During checkout or at later seat updates (e.g.
        adding or removing users), the higher number of actual and minumum seats
        will be used for the invoice. If you change this value after checkout,
        it needs to be adjusted manually in Chargebee, too.
      </Typography>
      <NumberInput
        source="customSeatPrice"
        label="Custom seat price (e.g. 9.50)"
      />
      <Typography
        variant="body2"
        style={{ minWidth: "500px", marginBottom: 20 }}
      >
        <span role="img" aria-label="point up">
          👆
        </span>{" "}
        This field is used to set a custom seat price for multi-seat plans
        (which means all except the Individual plan). This price can only be
        applied during checkout, so it needs to be set here BEFORE checkout. If
        it should be changed later, it needs to be changed here AND in Chargebee
        at the related subscription.
      </Typography>
      <DateInput source="trialEndsAt" />
      <DateInput disabled source="createdAt" />
      <DateInput disabled source="updatedAt" />
    </SimpleForm>
  );
}
