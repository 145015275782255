import {
  AutocompleteInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
} from "react-admin";

const isRequired = required();

export default function Form({ disableCallInput = false, ...props }) {
  return (
    <SimpleForm {...props}>
      <TextInput disabled source="id" />
      <ReferenceInput
        source="call"
        label="Call"
        reference="calls"
        filterToQuery={(q) => q && { title: { ilike: q } }}
        shouldRenderSuggestions={(val: string) => {
          return val.trim().length > 2;
        }}
        validate={[isRequired]}
      >
        <AutocompleteInput
          optionText="title.en"
          optionValue="id"
          // @ts-ignore
          options={{ disabled: disableCallInput }}
        />
      </ReferenceInput>
      <TextInput multiline source="whoCanSubmit" />
      <TextInput multiline source="theme" />
      <TextInput multiline source="whatToSubmit" />
      <TextInput multiline source="whatToWin" />
      <TextInput multiline source="costs" />
      <TextInput source="host" />
    </SimpleForm>
  );
}
