import { Edit, EditProps, Record } from "react-admin";
import Form from "./Form";

const Title = () => <span>Edit call info</span>;

const redirect = (_basePath: string, _id: string, data: Record) =>
  `/calls/${data.call}/show`;

export default function EditForm(props: EditProps) {
  return (
    <Edit title={<Title />} undoable={false} {...props}>
      <Form redirect={redirect} disableCallInput />
    </Edit>
  );
}
