import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
const styles = (theme) => ({
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  button: {
    marginRight: theme.spacing.unit * 2,
  },
});

const ConfirmedAction = ({ action, body, buttonLabel, classes, title }) => {
  const [revokeModalOpen, toggleRevokeModal] = useState(false);
  // action is a curried funtion, that expects the close action as parameter
  const actionHandler = action(() => toggleRevokeModal(false));
  return (
    <Fragment>
      <Button
        variant="contained"
        color="primary"
        onClick={() => toggleRevokeModal(true)}
      >
        {buttonLabel}
      </Button>
      <Modal open={revokeModalOpen} onClose={() => toggleRevokeModal(false)}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "500px",
          }}
        >
          <Paper className={classes.paper}>
            <Typography variant="title" id="modal-title" gutterBottom>
              {title}
            </Typography>
            <Typography
              variant="subheading"
              id="simple-modal-description"
              gutterBottom
            >
              {body}
            </Typography>
            <Button
              color="primary"
              onClick={actionHandler}
              className={classes.button}
            >
              Confirm
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => toggleRevokeModal(false)}
              className={classes.button}
            >
              Cancel
            </Button>
          </Paper>
        </div>
      </Modal>
    </Fragment>
  );
};

ConfirmedAction.propTypes = {
  action: PropTypes.func.isRequired,
  body: PropTypes.node.isRequired,
  buttonLabel: PropTypes.node.isRequired,
  title: PropTypes.node,
};

ConfirmedAction.defaultProps = {
  title: "Please confirm",
};

export default withStyles(styles)(ConfirmedAction);
