import isUrlSuperb from "is-url-superb";

export function url(message?: string) {
  return (value: string) => {
    if (!value) {
      return undefined;
    }
    if (!isUrlSuperb(value)) {
      return (
        message ||
        "Please provide a valid url (including a protocol like http://)"
      );
    }
    return undefined;
  };
}
