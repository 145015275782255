import { REL_TYPE_TO_ONE } from "./constants";

const submissionCategory = {
  attributes: {
    id: { editable: false },
    label: { editable: true },
    description: { editable: true },
    fileDateRangeRequirements: { editable: true },
    minImageLongEdge: { editable: true },
    minVideoLongEdge: { editable: true },
    minGifLongEdge: { editable: true },
    minImages: { editable: true },
    maxImages: { editable: true },
    minProjects: { editable: true },
    maxProjects: { editable: true },
    order: { editable: true },
    price: { editable: true },
    priceGraduation: { editable: true },
    imagePrice: { editable: true },
    imagePriceGraduation: { editable: true },
    requiredFields: { editable: true },
    hiddenFields: { editable: true },
    requiredImageFields: { editable: true },
    hiddenImageFields: { editable: true },
    singleImagesCategory: { editable: true },
    customRequirements: { editable: true },
    customFields: { editable: true },
    imageCustomFields: { editable: true },
    allowedFileTypes: { editable: true },
    minVideoDuration: { editable: true },
    maxVideoDuration: { editable: true },
  },
  relationships: {
    call: {
      type: "calls",
      editable: ["create"],
      relation: REL_TYPE_TO_ONE,
    },
  },
};
export default submissionCategory;
