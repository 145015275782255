import camelCaseKeys from "camelcase-keys";

const deserializeItem = ({ id, attributes, relationships }) => ({
  id,
  ...attributes,
  ...Object.entries(relationships || {})
    .filter(([, value]) => !!value.data)
    .map(([key, value]) => [
      key,
      Array.isArray(value.data)
        ? value.data.map(({ id }) => id)
        : value.data.id,
    ])
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value,
      }),
      {}
    ),
});

export default function deserialize(data) {
  const camelCasedData = camelCaseKeys(data, { deep: true });
  return Array.isArray(camelCasedData)
    ? camelCasedData.map(deserializeItem)
    : deserializeItem(camelCasedData);
}
