import React from "react";
import {
  AutocompleteInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
} from "react-admin";
import JSONField from "../../components/JSONField";

const isRequired = required();

export default function Form({ disableCallInput, ...props }) {
  return (
    <SimpleForm {...props}>
      <TextInput disabled source="id" />
      <ReferenceInput
        source="call"
        label="Call"
        reference="calls"
        filterToQuery={(q) => q && { title: { ilike: q } }}
        shouldRenderSuggestions={(val) => {
          return val.trim().length > 2;
        }}
        validate={[isRequired]}
      >
        <AutocompleteInput
          optionText="title.en"
          optionValue="id"
          options={{ disabled: disableCallInput }}
        />
      </ReferenceInput>
      <TextInput source="title" />
      <TextInput source="headline" />
      <TextInput multiline source="info" />
      <TextInput source="actionLabel" />
      <JSONField
        label="Form"
        source="formSchema"
        height="1000"
        validate={[isRequired]}
      />
    </SimpleForm>
  );
}
