import React from "react";
import {
  ReferenceInput,
  SimpleForm,
  TextInput,
  BooleanInput,
  BooleanField,
  DateInput,
  SelectInput,
} from "react-admin";
import Grid from "@material-ui/core/Grid";

export default function Form(props) {
  return (
    <SimpleForm {...props}>
      <Grid container spacing={2} style={{ width: "auto", maxWidth: "800px" }}>
        <Grid item xs={12}>
          <h2>General</h2>
        </Grid>
        <Grid item xs={12}>
          <TextInput disabled source="id" />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInput source="firstName" />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInput source="lastName" />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInput source="artistName" />
        </Grid>
        <Grid item xs={6} md={3}>
          <SelectInput
            source="gender"
            allowEmpty
            choices={[
              { id: "male", name: "male" },
              { id: "female", name: "female" },
              { id: "other", name: "other" },
            ]}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <DateInput disabled source="dateOfBirth" />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInput source="countryOrigin" />
        </Grid>
      </Grid>

      <h2>Account</h2>
      <TextInput disabled source="chargebeeSubscriptionId" />
      <ReferenceInput
        filter={{ type: { eq: "user" } }}
        source="plan"
        label="Plan"
        reference="plans"
      >
        <SelectInput optionValue="id" optionText="name" />
      </ReferenceInput>
      <DateInput disabled source="createdAt" />
      <DateInput disabled source="updatedAt" />
      <DateInput disabled source="deletion" />
      <DateInput disabled source="acceptedTermsAt" />
      <BooleanField source="isLockedOut" />
      <TextInput disabled source="lastActiveEmail" />
      <TextInput disabled source="acquisitionChannel" />
      <h2>Email</h2>
      <TextInput disabled source="email" />
      <BooleanInput source="emailVerified" />
      <TextInput disabled source="managedEmail" />
      <BooleanField source="newsletter" />
      <BooleanField source="notificationsDeadlineReminder" />
      <h2>Contact details</h2>
      <TextInput source="address.line1" />
      <TextInput source="address.line2" />
      <TextInput source="address.zip" />
      <TextInput source="address.city" />
      <TextInput source="countryResidence" />
      <TextInput source="phone" />
      <TextInput source="website" />
      <TextInput disabled source="timezone" />
      <h2>Profile</h2>
      <TextInput source="profession" />
      <TextInput source="companyName" />
      <TextInput multiline source="bioLong.en" fullWidth />
    </SimpleForm>
  );
}
