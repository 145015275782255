import React from "react";
import {
  AutocompleteInput,
  CloneButton,
  EditButton,
  Datagrid,
  DateField,
  Filter,
  ImageField,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
  TextInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useImageFieldStyles = makeStyles((theme) => ({
  image: {
    // This will override the style of the <img> inside the <div>
    width: 100,
    height: 100,
  },
}));

const Filters = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Organisation"
      source="organisation.eq"
      reference="organisations"
      filterToQuery={(q) => q && { name: { ilike: q } }}
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput
        shouldRenderSuggestions={(val) => {
          return val.trim().length > 2;
        }}
        optionText="name.en"
        optionValue="id"
      />
    </ReferenceInput>
    <TextInput label="Title" source="title.ilike" alwaysOn />
  </Filter>
);
export default function CallList(props) {
  const imageFieldClasses = useImageFieldStyles();
  return (
    <List
      {...props}
      filters={<Filters />}
      sort={{ field: "submissionEndDate", order: "DESC" }}
    >
      <Datagrid rowClick="show">
        <ImageField
          label="Logo"
          source="logoDefault"
          title="Logo"
          classes={imageFieldClasses}
        />
        <TextField label="Title" source="title.en" />
        <TextField label="Abbr." source="abbreviation" />
        <ReferenceField
          label="Organisation"
          source="organisation"
          reference="organisations"
        >
          <TextField source="name.en" />
        </ReferenceField>
        <DateField label="Start" source="submissionStartDate" />
        <DateField label="End" source="submissionEndDate" />
        <TextField label="Status" source="visibilityStatus" />
        <EditButton />
        <CloneButton />
      </Datagrid>
    </List>
  );
}
