import { useCallback } from "react";
import Typography from "@material-ui/core/Typography";
import { useFormState, useForm } from "react-final-form";
import csvtojson from "csvtojson";

interface CSVInputProps {
  source: string;
  parse?: (obj: any) => any;
  override?: boolean;
  label?: string;
  description?: string;
}

export default function CSVInput({
  source,
  parse = (obj) => obj,
  override = false,
  label,
  description,
}: CSVInputProps) {
  const { change } = useForm();
  const { values } = useFormState();
  const field = values[source];

  const handleChange = useCallback(
    (e) => {
      const file = e.target.files?.[0];

      if (!file) return;

      const reader = new FileReader();

      reader.onload = async (e) => {
        const string = e.target?.result as string;
        const obj = await csvtojson().fromString(string);
        const currentValue = field || [];
        const data = parse(obj);
        const newValue = override ? data : currentValue.concat(data);
        change(source, newValue);
      };

      reader.readAsText(file);
    },
    [field, source, override, parse, change]
  );

  return (
    <>
      <div>
        <Typography variant="button">
          {label || `import ${source} from CSV`}
          {`: `}
        </Typography>
        <input type="file" onChange={handleChange} />
      </div>
      {description && <Typography variant="caption">{description}</Typography>}
    </>
  );
}
