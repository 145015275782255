import React from "react";
import {
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  AutocompleteInput,
  TextInput,
} from "react-admin";
import JSONField from "../../components/JSONField";

const isRequired = required();

export default function Form(props) {
  return (
    <SimpleForm {...props}>
      <TextInput disabled source="id" />
      <ReferenceInput
        source="feature"
        label="Feature"
        reference="product-features"
        validate={[isRequired]}
      >
        <SelectInput optionValue="id" optionText="label" />
      </ReferenceInput>
      <ReferenceInput
        source="organisation"
        label="Organisation"
        reference="organisations"
        filterToQuery={(q) => q && { name: { ilike: q } }}
        shouldRenderSuggestions={(val) => {
          return val.trim().length > 2;
        }}
        validate={[isRequired]}
      >
        <AutocompleteInput optionText="name.en" optionValue="id" />
      </ReferenceInput>
      <JSONField source="config" height="200" validate={[isRequired]} />
    </SimpleForm>
  );
}
