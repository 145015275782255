function removeNullFields(object) {
  return Object.fromEntries(
    Object.entries(object).filter(([key, value]) => value !== null)
  );
}

// When cloning ads or changing the content of existing ads, unused content
// fields will be set to null instead of undefined (somewhere in the data pipeline
// between react-admin and our dataProvider).
// null values will still be send to the backend, which creates validation errors
// for unallowed fields. This custom transform removes all null fields from `content`
// and all containing `files`.

export default function transform({ content: { files, ...rest }, ...data }) {
  return {
    ...data,
    geoInclude: Array.from(new Set(data?.geoInclude?.flat().filter(Boolean))),
    geoExclude: Array.from(new Set(data?.geoExclude?.flat().filter(Boolean))),
    content: {
      ...removeNullFields(rest),
      files: files ? files.map(removeNullFields) : undefined,
    },
  };
}
