import React from "react";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "react-admin";

const styles = {
  button: {
    marginTop: "1em",
  },
};

const AddFeatureButton = ({ classes, record }) => (
  <Button
    className={classes.button}
    variant="raised"
    component={Link}
    to={{
      pathname: `/plan-product-features/create`,
      state: {
        record: { plan: record.id },
      },
    }}
    label="Add feature"
    title="Add feature"
  >
    <AddIcon />
  </Button>
);

export default withStyles(styles)(AddFeatureButton);
