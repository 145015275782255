import { REL_TYPE_TO_ONE } from "./constants";

const callInfo = {
  attributes: {
    id: { editable: false },
    whoCanSubmit: { editable: true },
    theme: { editable: true },
    whatToSubmit: { editable: true },
    whatToWin: { editable: true },
    costs: { editable: true },
    host: { editable: true },
  },
  relationships: {
    call: {
      type: "calls",
      editable: ["create"],
      relation: REL_TYPE_TO_ONE,
    },
  },
};
export default callInfo;
