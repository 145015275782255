import React from "react";
import {
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import JSONField from "../../components/JSONField";

const isRequired = required();

export default function Form(props) {
  return (
    <SimpleForm {...props}>
      <TextInput disabled source="id" />
      <ReferenceInput
        source="feature"
        label="Feature"
        reference="product-features"
        validate={[isRequired]}
        options={{ disabled: props.disableFeatureInput }}
      >
        <SelectInput optionValue="id" optionText="label" />
      </ReferenceInput>
      <ReferenceInput
        source="plan"
        label="Plan"
        reference="plans"
        validate={[isRequired]}
        options={{ disabled: props.disablePlanInput }}
      >
        <SelectInput optionValue="id" optionText="name" />
      </ReferenceInput>
      <JSONField source="config" height="200" validate={[isRequired]} />
    </SimpleForm>
  );
}
