import React from "react";
import {
  Show,
  TextField,
  ReferenceManyField,
  ReferenceField,
  Datagrid,
  EditButton,
  DeleteButton,
  SimpleShowLayout,
  Pagination,
} from "react-admin";
import AddUserButton from "./AddUserButton";

export default function ShowPage(props) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="phone" />
        <ReferenceField
          label="Plan"
          source="plan"
          reference="plans"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="subscriptionStatus" />

        <h2>Team</h2>
        <ReferenceManyField
          addLabel={false}
          reference="ws-space-users"
          target="space"
          pagination={<Pagination />}
        >
          <Datagrid>
            <ReferenceField label="User" source="user" reference="users">
              <TextField source="email" />
            </ReferenceField>
            <TextField source="scope" />
            <EditButton />
            <DeleteButton redirect={false} />
          </Datagrid>
        </ReferenceManyField>
        <AddUserButton />
      </SimpleShowLayout>
    </Show>
  );
}
