function removeNullFields(object: object) {
  return Object.fromEntries(
    Object.entries(object).filter(([key, value]) => value !== null)
  );
}

export default function transform({
  profileRequirements,
  ...data
}: {
  profileRequirements?: Array<string | object>;
}) {
  return {
    ...data,
    // For some unkown reason, profileRequirements can contain invalid null
    // values inside complex requirement objects. After some digging, it seems
    // that changing the requirements array causes some kind of merge of the
    // previous and the new array, thus merging strings with objects. This creates
    // weird objects like { "name": "countryResidence", valid:["US", "CA"], 0: null, 1: null, 2:null }.
    // This is a workaround to remove the null values within these objects.
    profileRequirements: profileRequirements
      ? profileRequirements.map((requirement: string | object) => {
          if (typeof requirement === "string") return requirement;
          return removeNullFields(requirement);
        })
      : undefined,
  };
}
