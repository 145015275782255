import {
  required,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  SimpleFormProps,
  Record,
  BooleanInput,
} from "react-admin";

const isRequired = required();

type FormProps = Omit<SimpleFormProps, "children"> & {
  disableCallInput?: boolean;
  disableUserInput?: boolean;
};

export default function Form({
  disableCallInput = false,
  disableUserInput = false,
  ...props
}: FormProps) {
  return (
    <SimpleForm {...props}>
      <ReferenceInput
        source="call"
        label="Call"
        reference="calls"
        filterToQuery={(q) => q && { title: { ilike: q } }}
        shouldRenderSuggestions={(val: string) => {
          return val.trim().length > 2;
        }}
        validate={[isRequired]}
      >
        <AutocompleteInput
          optionText="title.en"
          optionValue="id"
          // @ts-ignore
          options={{ disabled: disableCallInput }}
        />
      </ReferenceInput>
      <ReferenceInput
        source="user"
        label="User"
        reference="users"
        filterToQuery={(q) => q && { email: { ilike: q } }}
        shouldRenderSuggestions={(val: string) => {
          return val.trim().length > 3;
        }}
        validate={[isRequired]}
      >
        <AutocompleteInput
          optionText={(record: Record) =>
            record ? record.email || record.id || "" : ""
          }
          optionValue="id"
          // @ts-ignore
          options={{ disabled: disableUserInput }}
        />
      </ReferenceInput>
      <SelectInput
        source="scope"
        validate={[isRequired]}
        allowEmpty
        choices={[
          { id: "owner", name: "owner" },
          { id: "manager", name: "manager" },
        ]}
      />
      <BooleanInput label="Active" source="active" defaultValue={true} />
    </SimpleForm>
  );
}
