import React from "react";
import {
  CloneButton,
  Datagrid,
  EditButton,
  List,
  ReferenceField,
  TextField,
  Filter,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";

const Filters = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Call"
      source="call.eq"
      reference="calls"
      filterToQuery={(q) => q && { title: { ilike: q } }}
      shouldRenderSuggestions={(val) => {
        return val.trim().length > 2;
      }}
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput optionText="title.en" optionValue="id" />
    </ReferenceInput>
  </Filter>
);

export default function ListPage(props) {
  return (
    <List filters={<Filters />} {...props}>
      <Datagrid>
        <TextField source="eventType" />
        <TextField source="url" />
        <ReferenceField label="Call" source="call" reference="calls">
          <TextField source="title.en" />
        </ReferenceField>
        <EditButton />
        <CloneButton />
      </Datagrid>
    </List>
  );
}
