import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Field } from "react-final-form";
import { Labeled, composeValidators } from "react-admin";
import JSONEditor from "./components/JSONEditor";
import upperFirst from "lodash/upperFirst";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

export const isRequired = (validate) => {
  if (validate && validate.isRequired) return true;
  if (Array.isArray(validate)) {
    return !!validate.find((it) => it.isRequired);
  }
  return false;
};

const JSONInput = ({ input, label, height, isRequired, meta: { error } }) => {
  const [editorMode, setEditorMode] = useState("tree");
  // We can only allow switching to 'tree' mode, when value is valid JSON.
  const [isJsonValid, setIsJsonValid] = useState(true);

  const onChange = useCallback(
    (value) => {
      input.onChange(value || null);
      // JSONEditor only calls the onChange handler, when its value is valid JSON.
      // So we can safely set isJsonValid to true here.
      if (!isJsonValid) {
        setIsJsonValid(true);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [input.onChange, isJsonValid, setIsJsonValid]
  );
  const onJsonError = useCallback(() => {
    if (isJsonValid) {
      setIsJsonValid(false);
    }
  }, [isJsonValid, setIsJsonValid]);
  return (
    <React.Fragment>
      <Labeled label={label} isRequired={isRequired} fullWidth>
        <div>
          {editorMode === "tree" && (
            <Button
              onClick={() => setEditorMode("text")}
              disabled={!isJsonValid}
            >
              Code
            </Button>
          )}
          {editorMode === "text" && (
            <Button
              onClick={() => setEditorMode("tree")}
              disabled={!isJsonValid}
            >
              Tree
            </Button>
          )}
          <JSONEditor
            {...input}
            mode={editorMode}
            onChange={onChange}
            onJsonError={onJsonError}
            height={height}
            name={undefined}
          />
        </div>
      </Labeled>
      {error && (
        <Typography color="error" variant="caption">
          {error}
        </Typography>
      )}
    </React.Fragment>
  );
};

const JSONField = ({ source, label, validate, ...rest }) => (
  <Field
    {...rest}
    name={source}
    label={label || upperFirst(source)}
    component={JSONInput}
    isRequired={isRequired(validate)}
    validate={composeValidators(validate)}
  />
);

JSONField.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string.isRequired,
  height: PropTypes.number,
};

JSONField.defaultProps = {
  label: null,
  height: 400,
};

export default JSONField;
