import { REL_TYPE_TO_ONE } from "./constants";

const callUser = {
  attributes: {
    id: { editable: false },
    scope: { editable: true },
    active: { editable: true },
  },
  relationships: {
    call: {
      type: "calls",
      editable: ["create"],
      relation: REL_TYPE_TO_ONE,
    },
    user: {
      type: "users",
      editable: ["create"],
      relation: REL_TYPE_TO_ONE,
    },
  },
};
export default callUser;
