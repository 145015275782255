import { REL_TYPE_TO_ONE } from "./constants";

const callPage = {
  attributes: {
    id: { editable: false },
    slug: { editable: false },
    headerImage: { editable: true },
    headerImageCaption: { editable: true },
    brandColor: { editable: true },
    language: { editable: true },
    about: { editable: true },
    jurySectionTitle: { editable: true },
    jury: { editable: true },
    faq: { editable: true },
  },
  relationships: {
    call: {
      type: "calls",
      editable: ["create"],
      relation: REL_TYPE_TO_ONE,
    },
  },
};
export default callPage;
