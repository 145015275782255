import { REL_TYPE_TO_ONE } from "./constants";

const wsSpace = {
  attributes: {
    id: { editable: false },
    name: { editable: true },
    quantitySeats: { editable: true },
    quantitySeatsMin: { editable: true },
    customSeatPrice: { editable: true },
    trialEndsAt: { editable: true },
    phone: { editable: true },
  },
  relationships: {
    plan: {
      type: "plans",
      editable: ["create", "update"],
      relation: REL_TYPE_TO_ONE,
    },
  },
};
export default wsSpace;
