import React from "react";
import {
  Datagrid,
  List,
  TextField,
  Filter,
  SelectInput,
  ShowButton,
  EditButton,
} from "react-admin";

const Filters = (props) => (
  <Filter {...props}>
    <SelectInput
      alwaysOn
      source="type.eq"
      label="Type"
      choices={[
        { id: "space", name: "space" },
        { id: "organisation", name: "organisation" },
        { id: "user", name: "user" },
      ]}
    />
  </Filter>
);

export default function ListPage(props) {
  return (
    <List
      filters={<Filters />}
      sort={{ field: "type", order: "ASC" }}
      {...props}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="type" />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
}
