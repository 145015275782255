import axios from "axios";
import { HttpError } from "react-admin";

// Handle HTTP errors.
export default function init() {
  // Response interceptor
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const { status, data, statusText } = error.response || {};

      if (status < 200 || status >= 300) {
        console.log("error", status, data, statusText);
        return Promise.reject(
          new HttpError(
            (data && data.error) || statusText,
            status,
            data && data.validationErrors
          )
        );
      }

      return Promise.reject(error);
    }
  );
}
