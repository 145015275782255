import React from "react";
import {
  required,
  SimpleForm,
  TextInput,
  SelectInput,
  DateInput,
} from "react-admin";

const isRequired = required();

export default function Form(props) {
  return (
    <SimpleForm {...props}>
      <TextInput disabled source="id" />
      <TextInput source="name" validate={[isRequired]} />
      <TextInput source="slug" validate={[isRequired]} />
      <SelectInput
        source="type"
        validate={[isRequired]}
        choices={[
          { id: "space", name: "space" },
          { id: "organisation", name: "organisation" },
          { id: "user", name: "user" },
        ]}
      />
      <TextInput source="specialOfferCode" parse={(v) => v || null} />
      <TextInput source="referralDiscountCode" parse={(v) => v || null} />
      <DateInput disabled source="createdAt" />
      <DateInput disabled source="updatedAt" />
    </SimpleForm>
  );
}
