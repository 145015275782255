import {
  Show,
  TextField,
  DateField,
  SimpleShowLayout,
  FunctionField,
} from "react-admin";

import LinkToCopy from "./components/LinkToCopy";

export default function ShowPage(props) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <TextField label="Name" source="name" />
        <TextField source="campaign" />
        <DateField source="from" />
        <DateField source="to" />
        <FunctionField
          label="URL"
          source="url"
          render={({ url }) => <LinkToCopy href={url} />}
        />
        <FunctionField
          label="Click URL"
          source="clickUrl"
          render={({ clickUrl }) => <LinkToCopy href={clickUrl} />}
        />
        <FunctionField
          label="Tracking Pixel"
          source="impressionUrl"
          render={({ impressionUrl }) => (
            <LinkToCopy href={`${impressionUrl}&gif=true`} />
          )}
        />
        <FunctionField
          source="geoInclude"
          render={({ geoInclude }) => (geoInclude || []).join(", ")}
        />
        <FunctionField
          source="geoExclude"
          render={({ geoExclude }) => (geoExclude || []).join(", ")}
        />
        <TextField source="priority" />
        <TextField source="weight" />
        <TextField source="adSlot" />
      </SimpleShowLayout>
    </Show>
  );
}
