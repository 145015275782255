import React from "react";
import { Datagrid, List, ReferenceField, TextField } from "react-admin";

export default function ListPage(props) {
  return (
    <List {...props} sort={{ field: "name.en", order: "ASC" }}>
      <Datagrid rowClick="edit">
        <TextField source="name.en" />
        <TextField source="slug" />
        <TextField source="stripeUserId" />
        <ReferenceField label="Plan" source="plan" reference="plans">
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
}
