import React from "react";
import {
  required,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
} from "react-admin";

const isRequired = required();

export default function Form(props) {
  return (
    <SimpleForm {...props}>
      <ReferenceInput
        source="space"
        label="Space"
        reference="ws-spaces"
        filterToQuery={(q) => q && { name: { ilike: q } }}
        shouldRenderSuggestions={(val) => {
          return val.trim().length > 2;
        }}
        validate={[isRequired]}
      >
        <AutocompleteInput
          optionText="name"
          optionValue="id"
          options={{ disabled: props.disableSpaceInput }}
        />
      </ReferenceInput>
      <ReferenceInput
        source="user"
        label="User"
        reference="users"
        filterToQuery={(q) => q && { email: { ilike: q } }}
        shouldRenderSuggestions={(val) => {
          return val.trim().length > 3;
        }}
        validate={[isRequired]}
      >
        <AutocompleteInput
          optionText={(record) =>
            record ? record.email || record.id || "" : ""
          }
          optionValue="id"
          options={{ disabled: props.disableUserInput }}
        />
      </ReferenceInput>
      <SelectInput
        source="scope"
        validate={[isRequired]}
        allowEmpty
        choices={[
          { id: "owner", name: "owner" },
          { id: "manager", name: "manager" },
          { id: "member", name: "member" },
          { id: "viewer", name: "viewer" },
        ]}
      />
    </SimpleForm>
  );
}
