import React from "react";
import {
  AutocompleteInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
  NumberInput,
  DateInput,
  SelectInput,
} from "react-admin";

const isRequired = required();

export default function Form(props) {
  return (
    <SimpleForm {...props}>
      <TextInput disabled source="id" />
      <ReferenceInput
        source="call"
        label="Call"
        reference="calls"
        filterToQuery={(q) => q && { title: { ilike: q } }}
        shouldRenderSuggestions={(val) => {
          return val.trim().length > 2;
        }}
        validate={[isRequired]}
      >
        <AutocompleteInput
          optionText="title.en"
          optionValue="id"
          options={{ disabled: props.disableCallInput }}
        />
      </ReferenceInput>
      <TextInput source="code" />
      <SelectInput
        source="type"
        choices={[
          { id: "fixed", name: "Fixed" },
          { id: "percentage", name: "Percentage" },
        ]}
      />
      <NumberInput source="value" />
      <NumberInput source="usageLimit" />
      <DateInput source="expiresAt" />
    </SimpleForm>
  );
}
