import React from "react";
import {
  Datagrid,
  EmailField,
  BooleanField,
  DateField,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
  TextInput,
  SelectInput,
} from "react-admin";

const Filters = (props) => (
  <Filter {...props}>
    <ReferenceInput
      filter={{ type: { eq: "user" } }}
      source="plan.eq"
      label="Plan"
      reference="plans"
    >
      <SelectInput optionValue="id" optionText="name" />
    </ReferenceInput>
    <TextInput label="Email" source="email.ilike" alwaysOn />
    <TextInput label="First name" source="firstName.ilike" alwaysOn />
    <TextInput label="Last name" source="lastName.ilike" alwaysOn />
  </Filter>
);

export default function ListPage(props) {
  return (
    <List filters={<Filters />} {...props}>
      <Datagrid rowClick="edit">
        <TextField source="lastName" />
        <TextField source="firstName" />
        <EmailField source="email" />
        <TextField source="countryResidence" />
        <DateField source="deletion" />
        <TextField source="lastActiveEmail" />
        <BooleanField source="newsletter" />
        <ReferenceField label="Plan" source="plan" reference="plans">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  );
}
